@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;

  border-radius: 3px;

  background: #ebf1f5;
}

@bgSizeX: 300%;
@spinnerGradSize: 60% / (@bgSizeX / 100%);

.withWave
{
  background: linear-gradient(90deg, #ebf1f5 (50% - @spinnerGradSize / 2), #dde5ea 50%, #ebf1f5 (50% + @spinnerGradSize / 2));

  background-size: @bgSizeX 100%;
  animation: spinner 2s ease-in-out infinite;
}

@keyframes spinner
{
  0%
  {
    background-position: (100% + @spinnerGradSize) 0;
  }
  100%
  {
    background-position: -@spinnerGradSize 0;
  }
}

// ---------------------------------------

.spinnerIcon
{
  color: #8a9ba8;
}

// ---------------------------------------

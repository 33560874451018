@import '/src/styles/variables';

// ---------------------------------------

.pill
{
  margin: 0;
  padding: 0 7px 0 11px;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;

  cursor: pointer;
  user-select: none;

  background: linear-gradient(0deg, rgba(41, 101, 204, 0.2), rgba(41, 101, 204, 0.2)), #fff;

  &:hover
  {
    background: linear-gradient(0deg, @candidateFormHeaderBg, @candidateFormHeaderBg), #fff;
  }

  &.disabled
  {
    cursor: not-allowed;
    color: @colorTextLightGray;
    background: linear-gradient(0deg, #e1e8ed, #e1e8ed), #fff;

    .pillIcon
    {
      opacity: .4;
    }
  }

  &.unassigned
  {
    padding: 0 7px - 1 0 11px - 1;

    font-weight: 400;

    opacity: 0.6;
    border: 1px solid #5c7080;
    background: none;

    &:hover
    {
      background: rgba(41, 101, 204, 0.2);
    }
  }

  .pillIcon
  {
    color: @colorTextGray;
    opacity: .6;
  }
}

// ---------------------------------------

.positionGroupDropdown
{
  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }

    .bp4-menu
    {
      max-height: 302px;
      overflow-y: auto;
    }
  }
}

// ---------------------------------------

.dialogMenuItem
{
  .menuItemStyle();
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.dialogWrapper
{
  width: @dialogWidth_Default;
}

// ---------------------------------------

.inputAlias
{
  ::placeholder
  {
    color: @colorTextLightGray;
  }
}

// ---------------------------------------

.resetWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.positionName
{
  padding-right: 20px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btnReset
{
  flex-shrink: 0;
  margin: 4px -10px 0 auto;

  font-size: 12px;
  white-space: nowrap;

  &:hover
  {
    background: none !important;
  }
}

// ---------------------------------------

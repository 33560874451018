@import '/src/styles/variables';

// ---------------------------------------

.positionInfo
{
  margin-top: 14px;
  margin-bottom: 20px;

  border: 1px solid fade(@colorText, 20%);
  border-radius: 3px;

  &.isEditMode
  {
    border-color: @colorLink;

    .customer
    {
      background: @colorLink;
      color: #fff;

      .icon
      {
        color: #fff;
      }
    }
  }
}

// ---------------------------------------

.customer
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 25px;
  padding: 4px 8px 5px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: @colorTextGray;

  background: #ebf1f5;

  .icon
  {
    margin-top: 2px;
    margin-right: 9px;
    color: @colorTextGray;
  }
}

// ---------------------------------------

.customerNameWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.teamNameIcon
{
  margin: 0 2px;
  transform: translateY(.5px);
}

.positionNameWrapper
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.btnMore
{
  align-items: flex-start;
  padding-top: 3px;
}

// ---------------------------------------

.positionName
{
  flex: 1;
  padding: 10px 8px 6px;

  font-weight: 500;
  color: @colorText;
}

.staffRole
{
  padding: 0 8px 13px;

  font-size: 12px;
  line-height: 15px;
  color: @colorTextGray;
}

// ---------------------------------------


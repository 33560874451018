@import '/src/styles/variables';

// ---------------------------------------

.team
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  min-height: 40px;
  padding-right: 10px;

  cursor: pointer;

  font-size: 14px;
  line-height: 18px;

  //background: rgba(245, 248, 250, 0.5);
  //border-bottom: 1px solid @colorBorder;

  &:hover
  {
    .teamName
    {
      color: @colorLink;
    }
  }

  &.isExpanded,&:hover
  {
    @media (min-width: @siteBreakPointDesktopMin)
    {
      .actionBtns
      {
        display: flex;
      }
    }
  }
}

.team.isManagedTeam
{
  cursor: default;
  flex-wrap: wrap;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-wrap: nowrap;
  }

  .teamName
  {
    color: #fff;
  }

  .positions
  {
    display: none;
  }

  .pill
  {
    color: #fff;
    border-color: #fff;
  }

  .menuIcon
  {
    color: #fff !important;
  }
}

// ---------------------------------------

.teamName
{
  align-self: flex-start;
  flex-grow: 0;
  flex-shrink: 1;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 1px 0 3px;
  padding-right: 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  &.desktop
  {
    display: none;
  }

  &.mobile
  {
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @teamsListWidth_Name;

    margin: 11px 0;

    &.desktop
    {
      display: block;
    }

    &.mobile
    {
      display: none;
    }
  }

  &.prospective
  {
    color: @colorTextGray;
  }
}

.prospectiveLabel
{
  margin-left: 8px;
  font-weight: 400;
}

// ---------------------------------------

.mobileWrapper
{
  display: flex;
  flex-direction: row;
  //flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  padding-right: 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-grow: 0;
    width: @teamsListWidth_Alias;
  }
}

.aliases
{
  display: flex;
  flex-wrap: wrap;

  flex: 1;
  padding-right: 10px;

  margin: 10px 0 5px;

  .pill
  {
    margin: 0 10px 5px 0;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin: 10px 0 5px 0;
  }
}

// ---------------------------------------

.positions
{
  margin: 11px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    //flex-grow: 1;
    //flex-shrink: 0;
    padding-right: 10px;
  }
}

// ---------------------------------------

.actionBtnsWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-grow: 1;
    flex-shrink: 0;
    //padding-right: 10px;
  }
}

.actionBtns
{
  display: none;
  //margin-left: auto;
  margin-bottom: 8px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-top: 8px;
  }
}

.btnNewPosition,
.btnManagePositions
{
  margin-left: 10px;
  white-space: nowrap;
}

.btnNewPosition
{
  color: @colorTextGray !important;
}

.btnNewPositionIcon
{
  color: @colorTextGray08 !important;
}

.btnCancel,
.btnApply
{
  width: 50%;
  max-width: 120px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: auto;
  }
}

.btnCancel
{
  //min-width: 120px !important;
  //max-width: 120px !important;
  border: 1px solid #fff;
  color: #fff !important;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-left: 22px;
    border: none;
  }
}

.btnApply
{
  margin-left: 10px;
  margin-right: 10px;
  background: #fff !important;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-right: 38px;
  }
}

.team.isManagedTeam
{
  .actionBtnsWrapper
  {
    flex: 1;
    min-width: 100%;
    justify-content: flex-start;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      justify-content: flex-end;
      min-width: auto;
    }
  }

  .actionBtns
  {
    display: flex;
    flex: 1;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      flex: 0;
    }
  }

  .btnNewPosition
  {
    display: none;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      display: flex;
      color: #fff !important;
      border-color: #fff;

      .btnNewPositionIcon
      {
        color: #fff !important;
      }
    }
  }
}

// ---------------------------------------

.menu
{
  align-self: flex-start;
  margin-left: 0;
  margin-top: 0;
  margin-right: -10px;

  &.mobile
  {
    @media (min-width: @siteBreakPointDesktopMin)
    {
      display: none;
    }
  }
}

.menuBtn
{
  min-width: 48px;
  min-height: 40px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.toolbarPlaceholder
{
  min-height: 60px;
}

.positionLeft
{
  padding-left: 0;

  @media (min-width: @siteBreakPointMobileMax)
  {
  }
}

.positionCenter
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.positionRight
{
  max-width: 50%;
  min-width: 120px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

// ---------------------------------------

.btnArrowLeft
{
  margin-left: -8px;
  margin-right: 8px;
}

.btnMore
{
  margin-right: -6px;
  margin-left: 5px;
}

// ---------------------------------------

.assignedTo
{
  display: flex;
  align-items: center;

  margin-right: 20px;

  white-space: nowrap;
  font-size: 14px;
  color: @colorTextGray;
}

.assignedToIcon
{
  margin-right: 10px;
}

// ---------------------------------------

.avatar
{
  margin-right: 10px;
}

.name
{
  font-size: 19px;
  color: @colorText;
  font-weight: 900;
  letter-spacing: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ---------------------------------------

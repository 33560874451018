@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  display: flex;
  height: 1.5em;
}

.inner
{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.plusX
{
  white-space: nowrap;
  min-width: 20px;
}

// ---------------------------------------

@import '/src/styles/variables';

@minHeightRows: 40px;

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  padding-right: 20px;
  padding-bottom: 10px;
}

// ---------------------------------------

.time
{
  padding-top: 1px;
  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;
  text-align: right;
}

// ---------------------------------------

.usersRow
{
  display: flex;
  align-items: center;
  flex: 1;

  // TODO: !!! scroll bar width
  //padding-right: 15px;

  .timeWrapper
  {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    min-width: 61px;
    padding-right: 11px;
    align-self: stretch;

    &:after
    {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 11px;
      width: 1px;
      background: @colorGray15;
    }
  }

  .time
  {
    position: relative;
    flex: 1;
    font-size: 12px;
    color: @colorTextGray;
    text-align: right;
    padding-bottom: 11px;

    &:after
    {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      height: 1px;
      width: 4px;
      background: @colorGray15;
    }
  }
}

// ---------------------------------------

.users
{
  display: flex;
  align-items: center;
  flex: 1;
}

.user
{
  position: relative;
  display: flex;
  align-items: flex-start;
  flex: 1;

  //margin-left: -1px;
  padding: 14px 14px 8px 14px;

  border-bottom: @borderGray15;

  &:after
  {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 10px;
    width: 1px;
    background: @colorGray15;
  }
}

.avatar
{
  margin-right: 10px;
}

.userText
{
  display: flex;
  flex-direction: column;
}

.userName
{
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 12px;
}

.userTime
{
  font-size: 12px;
  line-height: 15px;
  color: @colorTextGray;
}

// ---------------------------------------

.hours
{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hourRow
{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: @minHeightRows;
  border-bottom: @borderGray15;

  .timeWrapper
  {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: @minHeightRows;
    min-width: 61px;

    border-right: @borderGray15;
  }

  .time
  {
    min-width: 60px - 4;
    padding-right: 6px;

    transform: translateY(50%);
    background: #fff;
  }
}

.hour
{
  position: relative;
  flex: 1;
  min-height: @minHeightRows;
  border-right: @borderGray15;

  &.isBusy
  {
    &:after
    {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: repeating-linear-gradient(-45deg, #d5d6d7 0px, #d5d6d7 1px, #f3f3f3 1px, #f3f3f3 7.25px);
      background-size: 10px 10px;
      background-attachment: fixed;
    }
  }
}

//.isBusy
//{
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 1px;
//  background: #f3f3f3;
//}

// ---------------------------------------

.event
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 5px;
  width: calc(100% - 10px);
  //right: 5px;
  //padding: 5px 10px;

  background: #ced3e0;
  border-radius: 3px;

  &.topOut
  {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:before
    {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .3);
    }
  }

  &.bottomOut
  {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:after
    {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .3);
    }
  }

  &.isSelected
  {
    z-index: 2;
    background: #7ca0df;
    border: 1px solid #2965cc;
    .grayShadow;

    .eventHours
    {
      margin: 5px 9px;

      font-size: 14px;
      font-weight: 500;

      &.lessThenOr30min
      {
        margin: auto 9px !important;
      }

      &.lessThenOr15min
      {
        line-height: 8px;
      }
    }
  }

  &.isNotValid
  {
    //background: fade(#f5903d, 60);
    //border-color: fade(#f5903d, 60);
    background: fade(#f00, 20);
    border-color: fade(#f00, 20);
    //border-color: rgba(0, 0, 0, .2);
  }

  //&.isHidden
  //{
  //  display: none;
  //}
}

.eventHours
{
  margin: 6px 10px;

  font-size: 12px;
  line-height: 15px;

  &.lessThenOr30min
  {
    margin: auto 10px !important;
    justify-self: center;

    line-height: 10px;
  }

  &.lessThenOr15min
  {
    // for duration 15 min & min-height 10px
    //padding-top: 1px;
    //font-size: 11px;

    // for duration 15 min & min-height 9px
    font-size: 10px !important;
  }
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.selectMore
{
  :global .bp4-menu
  {
    min-width: 150px;
  }

  //:global .bp4-menu-item
  //{
  //  font-size: 16px;
  //  line-height: 30px;
  //  padding: 5px 10px;
  //}
}

//.menuItemMoreActions
//{
//  :global .bp4-menu
//  {
//    min-width: 125px;
//  }
//}

.menuItemMoreActions
{
  //padding: 6px 11px 5px;
  //font-size: 16px;
  //line-height: 29px;
}

// ---------------------------------------

.btnMoreActions
{
  //min-width: 125px !important;
  margin-left: 25px;
  font-size: 14px !important;
  color: @colorTextLightGray !important;

  &:hover
  {
    color: @colorLink !important;
    border-color: @colorLink;
    background: rgba(41, 101, 204, 0.1) !important;
  }

  &.isDisabled
  {
    color: @disabledButtonColor !important;
    border-color: @disabledButtonBorderColor !important;
  }
}

// ---------------------------------------

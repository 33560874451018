@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  padding: 10px;
  min-height: 51px;
  border-bottom: @borderGray15;

  flex-direction: column;

  @media (min-width: @siteBreakPointMobileMax)
  {
    flex-direction: row;
    padding: 10px 20px 10px 8px;
  }
}

.positionLeft,
.positionCenter,
.positionRight
{
  display: flex;
  flex-direction: row;
  align-items: center;
}

// ---------------------------------------

.icon
{
  margin-right: 13px;
  color: @colorTextGray;
}

.iconBtn
{
}

.title
{
  margin-left: 8px;
  font-size: 18px;
}

// ---------------------------------------

.btn
{
  min-width: 125px;
  margin-left: 5px;
}

// ---------------------------------------

@import '/src/styles/variables';

.toolbar
{
  .positionLeft
  {
    @media (min-width: @siteBreakPointTabletMin)
    {
      width: 30%;
    }
  }

  .positionCenter
  {
    @media (min-width: @siteBreakPointTabletMin)
    {
      width: 40%;
    }
  }

  .positionRight
  {
    @media (min-width: @siteBreakPointTabletMin)
    {
      width: 30%;
    }
  }

  .btnAdd
  {
    display: none;

    margin-right: 20px;

    white-space: nowrap;

    @media (min-width: @siteBreakPointTabletMax)
    {
      display: block;
      flex-wrap: nowrap;
    }
  }

  .viewMobile
  {
    @media (min-width: @siteBreakPointTabletMax)
    {
      display: none;
    }
  }
}

.secondRow
{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: none;
  }
}

.statusSwitcher
{
  &.mobile
  {
    @media (min-width: @siteBreakPointMobileMax)
    {
      display: none;
    }
  }

  &.desktop
  {
    @media (max-width: @siteBreakPointMobileMax)
    {
      display: none;
    }
  }
}

@import '/src/styles/variables';

.interviewFlow
{
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;

  height: 100%;
  min-height: 0;
}

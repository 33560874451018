@import '/src/styles/variables';

.buttonGroup
{
}

.buttonGroupBtn
{
  min-width: 100px;

  //color: @colorActionBg;
  //background: none !important;
  //box-shadow: inset 0 0 0 1px @colorActionBg !important;

  &.isActive
  {
    //color: #FFFFFF;
    //background: @colorActionBg !important;
  }
}

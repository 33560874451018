@import '/src/styles/variables';

// ---------------------------------------

.dialogClassName
{
  width: @dialogWidth_Large;
}

// ---------------------------------------

.titleText
{
  white-space: nowrap;
  align-self: flex-start;
}

.positionGroups
{
  text-align: right;
  padding-top: 3px;
  font-size: 14px;
  line-height: 18px;
  color: @colorTextGray;
}

// ---------------------------------------

.contentWrapper
{
  max-height: 50vh;
  overflow-y: auto;
  margin-top: -20px;
  padding-bottom: 1px;
}

// ---------------------------------------

.allocations
{
  padding-bottom: 15px;
}

// ---------------------------------------

.noPositions
{
  margin-top: 15px;
}

// ---------------------------------------

.customerAndTeam + .positionItem
{
  border-top-color: transparent;
  margin-top: 0 !important;
}

.positionItem
{
  &:last-child
  {
    margin-bottom: 0;
  }

  &.selectedBeforeCustomerName
  {
    margin-bottom: 8px;
  }

  //.afterSelectedPosition,
  .afterCustomerName
  {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .beforeSelectedPosition,
  .beforeCustomerName
  {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

// ---------------------------------------

// TODO: remove
.tmpPositionItem
{
  display: flex;
  align-items: center;

  padding: 11px 20px 11px 10px;
  font-weight: 500;
  color: @colorText;

  min-height: 40px;
  background: linear-gradient(0deg, rgba(191, 204, 214, 0.3), rgba(191, 204, 214, 0.3)), #fff;
  border-top: @borderGray15;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

// ---------------------------------------

.header
{
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px 3px 0 0;
  //border-bottom: @borderGray15;
}

// ---------------------------------------

.content
{
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.panel
{
  position: relative;
  display: flex;
  flex: 1;

  width: 100%;
  padding: 0 10px;
  overflow-y: auto;
}

// ---------------------------------------

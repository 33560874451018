@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 40px;
}

// ---------------------------------------

.staffedText
{
  display: flex;
  align-items: center;

  color: @colorTextGray;
}

.iconClock
{
  margin-right: 10px;
  color: @colorTextLightGray;
}

// ---------------------------------------

.btn
{
  min-width: 125px !important;
  margin-left: 25px;
  font-size: 14px !important;

  &.isDisabled
  {
    color: @disabledButtonColor !important;
    border-color: @disabledButtonBorderColor !important;
  }
}

// ---------------------------------------



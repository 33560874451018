@import "/src/styles/variables";

// ---------------------------------------

.selectWrapper
{
  .popover
  {
    max-width: 300px;

    margin-top: 3px !important;
    //max-height: 310px;
    //overflow-y: auto;
  }

  :global
  {
    .bp4-menu
    {
      max-height: 40vh;
      overflow-y: auto;
    }
  }
}

// ---------------------------------------


@import '/src/styles/variables';

// ---------------------------------------

.dialogClassName
{
  width: @dialogWidth_Large;
}

// ---------------------------------------

.titleText
{
  white-space: nowrap;
  align-self: flex-start;
}

.titleAdditionalContent
{
  display: flex;
}

.positionGroups
{
  flex: 1;
  text-align: right;
  padding-top: 3px;
  margin-right: 10px;

  font-size: 14px;
  line-height: 18px;
  color: @colorTextGray;
}

// ---------------------------------------

.contentWrapper
{
  max-height: 50vh;
  overflow-y: auto;
  margin-top: -20px;
  padding-bottom: 1px;
}

// ---------------------------------------

.allocations
{
  padding-bottom: 15px;
}

// ---------------------------------------

.notesInputWrapper
{
  padding-top: 20px;

  .notesInput
  {
    min-height: 90px;
    box-shadow: none !important;
    border: 1px solid @colorGray20;
    resize: vertical;
  }
}

.noteBtnWrapper
{
  margin-top: 25px;
  margin-bottom: -60px;
}

.showNoteBtn
{
  margin-left: -10px;

  &:hover
  {
    background: none !important;
  }
}

// ---------------------------------------

.noPositions
{
  margin-top: 15px;
  background: linear-gradient(0deg, rgba(191, 204, 214, 0.3), rgba(191, 204, 214, 0.3)), #fff !important;
}

// ---------------------------------------

.customerAndTeam + .positionItem
{
  border-top: 0;
  margin-top: 0 !important;
}

.positionItem
{
  &:last-child
  {
    margin-bottom: 0;
  }
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.position
{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  min-height: 40px;

  cursor: pointer;

  font-size: 14px;
  line-height: 18px;

  margin-bottom: -1px;
  padding-bottom: 1px;

  &:hover
  {
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;

    .positionName
    {
      color: @colorLink;
    }
  }

  &.blueBorderTop
  {
    margin-top: -1px;
    border-top: 1px solid @colorLink !important;
  }

  &.blueBorderBottom
  {
    margin-bottom: -2px;
    border-bottom: 1px solid @colorLink;
  }

  &.blueBorderLeftRight
  {
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid @colorLink;
    border-right: 1px solid @colorLink;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

// ---------------------------------------

.positionName
{
  padding-left: 10px;
  padding-right: 10px;

  margin: 11px 0 4px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: normal;
  line-height: 18px;

  &.desktop
  {
    display: none;
  }

  &.mobile
  {
    display: block;
    width: 100%;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @teamsListWidth_Name;
    margin: 11px 0;

    &.desktop
    {
      display: block;
    }

    &.mobile
    {
      display: none;
    }
  }
}

.templateIcon
{
  margin-left: -1px;
  margin-right: 11px;
  color: #f5903d;

  &.isReady
  {
    margin-left: 0;
    margin-right: 10px;
    color: @colorLink;
  }
}

// ---------------------------------------

.mobileWrapper
{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-grow: 0;
    width: @teamsListWidth_Alias;
  }
}

.staffRoleName
{
  margin-bottom: 11px;
  padding-left: 10px;
  padding-right: 10px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: @colorTextGray;
  font-size: 12px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin: 11px 0;
    padding-left: 0;
  }
}

// ---------------------------------------

.positionsWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.positions
{
  margin: 11px -10px 11px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding-right: 10px;
  }
}

.btnMoveUp,
.btnMoveDown
{
  display: none;
  margin: 0 5px;
  opacity: .6;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
  }
}

.btnMoveIcon
{
  color: @colorTextGray !important;
}

.btnMoveUp
{
  margin-left: auto;
}

.btnMoveDown
{
  margin-right: 10px;
}

.disabledMoveBtn()
{
  opacity: 0.4;
}

.position
{
  background: linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #fff;
  border-top: @borderGray15;

  &.isFirst
  {
    border-top: none;
    border-top-left-radius: @collapseListItemsBorderRadius;
    border-top-right-radius: @collapseListItemsBorderRadius;

    .btnMoveUp
    {
      .disabledMoveBtn();
    }
  }

  &.isLast
  {
    padding-bottom: 0;
    border-bottom-left-radius: @collapseListItemsBorderRadius;
    border-bottom-right-radius: @collapseListItemsBorderRadius;

    .btnMoveDown
    {
      .disabledMoveBtn();
    }
  }
}

// ---------------------------------------

.quantity
{
  flex-grow: 0;
  flex-shrink: 0;

  //height: 30px;
  width: 64px; // 70px
  margin-left: 5px;
}

.inputCount
{
  height: 30px;
  min-width: 30px; // 23px;
  line-height: 30px;
  margin-right: 3px;

  text-align: right;
  color: #8a9ba8;
}

.inputCountSpan
{
  margin-right: 2px;
}

// ---------------------------------------

.deleteBtn
{
  position: absolute;
  left: -35px;
  top: 1px;
  bottom: 2px;
  width: 33px;

  .deleteBtnIcon
  {
    color: #8a9ba8;
  }

  &:hover
  {
    //background: none !important;

    .deleteBtnIcon
    {
      color: @colorIcons;
    }
  }
}

// ---------------------------------------

.menu
{
  align-self: flex-start;
  margin-top: 0;
  margin-right: 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

.menuBtn
{
  min-width: 48px;
  min-height: 40px;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{

}

.link
{
  color: inherit;

  &:hover
  {
    //color: @colorLink; // default link styles from index.less
  }
}

// ---------------------------------------

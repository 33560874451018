@import "/src/styles/variables";

@-webkit-keyframes fade
{
  0%,100% { opacity: 0.7 }
  50% { opacity: 1 }
}

@keyframes fade
{
  0%,100% { opacity: 0.5 }
  50% { opacity: 1 }
}

.loadingOverlay
{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: @zindexPageHeader + 1;
}

.loadingElement
{
  min-width: 172px;
  opacity: 0;
  -webkit-animation: fade 1.42s 0s infinite;
  animation: fade 1.42s 0s infinite;
}

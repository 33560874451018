@import "/src/styles/variables";

// ---------------------------------------

.topOverlay
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 15px;
  height: 10px;
  z-index: 1;
  background: #fff;
}

.content
{
  max-height: 310px;
  overflow-y: auto;

  //margin-top: -4px;
  padding: 6px 10px 8px 10px;
  margin: -10px -15px -10px -15px;
}

.checkbox
{
  display: flex;
  align-items: center;

  margin: 0 5px;
  padding: 6px 0;

  //&:hover
  //{
  //  .checkboxTitle
  //  {
  //    color: @colorLink;
  //  }
  //}
}

.checkboxTitle
{
  padding-bottom: 1px;
  line-height: 17px;
  .textEllipsis();
}

// ---------------------------------------

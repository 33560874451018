@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.scrolled
{
  flex: 1;
  min-height: 0;
  overflow-y: auto;

  padding: 0 20px 15px;
}

// ---------------------------------------

.customerAndTeamWrapper
{
  display: flex;
}

.customerAndTeam
{
  flex: 1;
}

.positionName
{
  font-weight: 700;
}

.btnMore
{
  margin-right: -13px;
  margin-top: 6px;
}

// ---------------------------------------

.slotWrapper
{
  display: flex;
  flex-direction: column;

  position: relative;
  padding: 0 15px 5px;
  margin-top: 1px;
  width: 100%;

  border: 1px solid @colorSlotBlue;
  border-radius: 3px;

  &:first-child
  {
    //margin-top: 0;
  }

  &.renderTwoUsersSlot
  {
    border-color: @colorSlotOrange;
  }

  &.isLeaving
  {
    border-color: @colorSlotOrange;
  }
}

.positionHeader
{
  width: 100%;
  padding: 0;
}

.slot
{
  margin-top: 10px;
  margin-bottom: 2px;
}

.row
{
  display: flex;
  flex-direction: row;
  background: #fcc;
}

.iconBefore
{
  margin-right: 16px;
}

// ---------------------------------------

.staffWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 10px;
  line-height: 18px;

  background: #fcc;
}

.name
{
  position: relative;
  margin-left: 32px;
  margin-right: 10px;

  &:before
  {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -26px;
    width: 6px;
    height: 6px;
    background-color: @colorSlotBlue;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.iconDate
{
  margin-right: 10px;
  color: @colorSlotBlue;
}

.date
{
  font-size: 12px;
  color: @colorTextGray;
}

.dateText
{
  margin-left: auto;
  font-size: 12px;
  color: @colorSlotBlue;
}

.renderTwoUsersSlot
{
  .name::before
  {
    background-color: @colorSlotOrange;
  }

  .iconDate
  {
    color: @colorSlotOrange;
  }

  .dateText
  {
    color: @colorSlotOrange;
  }
}

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  width: 100%;
  // gap: 10px;

  .messageRow
  {
    flex: 1;
    min-height: 30px;
  }

  .actionRow
  {
    display: flex;
    justify-content: flex-end;

    width: calc(100% + 35px);
  }
}

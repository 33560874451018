@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  position: fixed;
  z-index: 1;
  top: @appHeaderHeight;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;

  &.isOpen
  {
    pointer-events: all;

    .overlay
    {
      transition: opacity 0.3s ease-out; // show

      opacity: 1;
    }

    .contentWrapper
    {
      transition: transform 0.3s ease-out; // show
      transform: translateX(0);
      //opacity: 1;
      //transition: transform .3s ease-in, opacity .3s ease-in; // show
    }
  }
}

.overlay
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transition: opacity 0.3s ease-in; // hide

  opacity: 0;
  background: @colorBgOverlay;
}

// ---------------------------------------

.contentWrapper
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;

  width: 565px;
  max-width: 90vw;
  padding: 0 24px 24px;

  transition: transform 0.1s ease-out; // hide
  transform: translateX(100%);

  background: #FFFFFF;
  //opacity: 0;
  //transition: transform .1s ease-out, opacity .1s ease-out; // hide
}

// ---------------------------------------

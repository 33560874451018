@import "/src/styles/variables";

// ---------------------------------------

//@searchWrapperWidth: (1218px - 24 - 24);
//@searchWidth: 518px;
//@searchWidthPerc: (@searchWidth / @searchWrapperWidth) * 100%;
//@leftRightWidth: (100 - @searchWidthPerc) / 2;

.positionLeft
{
  width: 200px;

  @media (min-width: @breakPointAndroidCommon)
  {
    width: 50%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    //width: @leftRightWidth;
  }
}

.positionCenter
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    //width: @searchWidthPerc;
  }
}

.positionRight
{
  width: 50%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    //width: @leftRightWidth;
  }
}

// ---------------------------------------

.btnArrowLeft
{
  margin-left: -8px;
  margin-right: 8px;
}

.btnMore
{
  margin-right: -10px;
  margin-left: 10px;
}

// ---------------------------------------

.btnAdd
{
  white-space: nowrap;
  margin-right: 20px;
  margin-left: 30px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    margin-left: 0px;
  }
}

.searchInput
{
  flex: 1;
  min-width: 240px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.popover
{
  display: flex !important; // popover

  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-left: 1px !important;
      margin-top: 3px !important;
    }
  }
}

// ---------------------------------------


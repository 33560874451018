@import "/src/styles/variables";

@widthInputClosed: 270px;
@widthInputOpened: 340px;

// ---------------------------------------

.searchInput
{
  display: flex;
  width: 85px;
  min-width: 85px;
  transition: width .1s ease-out;

  @media (min-width: @siteBreakPointMobileMax)
  {
    width: @widthInputClosed;
  }

  &.isFocused
  {
    position: absolute;
    z-index: @zindexBPPortal + 1;
    width: 100vw;
    max-width: calc(100vw - 20px);

    @media (min-width: @siteBreakPointMobileMax)
    {
      position: relative;
      width: @widthInputOpened;
    }
  }
}

.targetClassName
{
  flex: 1;
}

// ---------------------------------------

// => searchInput
.searchPopover
{
}

.popoverClassName
{
  //width: 100%;
  width: calc(100vw - 20px);
  margin-right: 5px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    width: @widthInputOpened;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-right: 20px;
  }

  :global .bp4-menu
  {
    max-height: 70vh;
    overflow-y: auto;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      max-height: 60vh;
    }
  }
}

.backdrop
{
  background: @colorBgOverlay;
}

// ---------------------------------------

.inputClassName
{
  :global .bp4-input
  {
    padding-left: 40px !important;
    background: none !important;
    border: 1px solid rgba(138, 155, 168, 0.5);
    box-shadow: none;
  }

  :global .bp4-icon
  {
    top: 1px;
    left: 6px !important;

    svg
    {
      width: 15px;
      height: 15px;
    }
  }

  @media (max-width: @siteBreakPointMobileMax - 1)
  {
    :global .bp4-input
    {
      padding-left: 30px !important;
    }

    :global .bp4-icon
    {
      left: 2px !important;
    }
  }

  :global .bp4-input::placeholder
  {
    color: rgba(24, 32, 38, 0.5);
  }

  :global .bp4-input:focus
  {
    background: #fff !important;
    box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, .3), inset 0 1px 1px rgba(17, 20, 24, .2);
    border: none;
    //box-shadow: 0 0 0 2px rgba(45, 114, 210, .3);
    //border-color: @colorLink;
  }
}

// ---------------------------------------

.menuItemText
{
  display: flex;
  flex-direction: row;
}

.avatar
{
  margin-right: 14px;
}

.text
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 0;
}

.name
{
  padding-left: 2px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 16px;
  color: @colorText;
  //font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.otherText
{
  padding-left: 2px;
  font-size: 12px;
  line-height: 14px;
  color: @colorTextGray;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// ---------------------------------------

.token
{
  color: #000;
  font-weight: 600;
  background: #d8e1e8;
  border-radius: 2px;
  padding: 0 1px;
  margin: 0 -1px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 96px;

  background: #ebf1f5;
  border-radius: 3px;

  font-size: 12px;
  line-height: 15px;
  color: @colorTextLightGray;

  &.withBorder
  {
    background: linear-gradient(0deg, #f5f8fa, #f5f8fa), #fff;
    border: 1px solid #c4c4c4;
  }
}

.title
{
  margin-top: 7px;
}

.content
{

}

// ---------------------------------------

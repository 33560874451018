@import "/src/styles/variables";

// ---------------------------------------

.positionGroupDialog
{
}

.positionGroupSelect
{
  .popoverWrapper();
}

.unselected
{
  color: @colorTextGray !important;
}

// ---------------------------------------

.dialogMenuItem
{
  padding: 6px 11px 5px;
  font-size: 16px;
  line-height: 29px;
}

// ---------------------------------------

.inputIcon
{
  opacity: 0.5;
  padding: 12px;
  color: #0d8050 !important;

  &.isNotValid
  {
    color: #eb8787 !important;
  }

  &.disabled
  {
    color: @colorTextLightGray !important;
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  position: relative;
  display: flex;
  flex: 1;

  width: 100%;
  min-height: 232px;
}

.content
{
  overflow-y: auto;
  color: @colorTextGray;
}

.spinnerWrapper
{
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
}

.uploadBtn
{
  color: @colorTextGray !important;
  white-space: nowrap;
}

.uploadingWrapper
{
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: @colorTextGray;
}

// ---------------------------------------

.headerWrapper
{
  min-height: 50px;
  padding: 5px 10px 5px 20px;
}

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
}

.noAttachments
{
  margin: 10px;
}

.colHeaders
{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .col
  {
    padding: 0 5px;

    &.file
    {
      width: 50%;
    }

    &.user
    {
      width: 20%;
    }

    &.type
    {
      width: 20%;
    }

    &.upload
    {
      width: 30%;
      min-width: 120px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.itemWrapper
{
  min-height: 40px !important;
}

.row
{
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;

  &.isDeleting
  {
    opacity: 0.5
  }
}

.colData
{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 40px;

  padding: 5px 10px 5px 20px;

  .col
  {
    padding: 0 5px;

    &.attachmentNameBlock
    {
      display: inline-block;
      width: 50%;

      .attachmentNameContainer
      {
        display: flex;

        .fileTypeIcon {
          margin-right: 9px;
        }
      }
    }

    &.user
    {
      width: 20%;
    }

    &.type
    {
      width: 35%;
    }

    &.menu
    {
      width: 15%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.attachmentName
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip
{
  width: calc(100% - 25px);
}

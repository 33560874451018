@import "/src/styles/variables";

// ---------------------------------------

.positionsWrapper
{
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.otherPositionsWrapper
{
  flex: 1;
  background: #fff;
}

.positionWrapper
{
  //padding-bottom: 2px;

  border-bottom: @borderGray15;

  .content
  {
    padding-bottom: 2px;
    background: #fff;
  }

  &:last-child
  {
    border-bottom: none;
  }

  &.isTeamLead
  {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: none;
  }

  &.hiddenTeamLead
  {
    max-width: 100%;

    @media (min-width: @siteBreakPointMobileMax)
    {
      visibility: hidden;
      overflow: hidden;
      max-width: 100%;

      // TODO: debug -> delete
      //z-index: 1;
      //.content
      //{
      //  background: #ccf;
      //  box-shadow: inset 0 0 0 1px rgba(255, 0, 0, 0.5);
      //}
    }

    .content
    {
      max-width: 100%;
    }
  }

  &.firstUnitedTeamLead
  {
    display: none;

    @media (min-width: @siteBreakPointMobileMax)
    {
      display: block;
      position: absolute;
      z-index: 2;

      // TODO: debug -> delete
      //opacity: .5;
      //display: none;
      //.content
      //{
      //  background: #fcc;
      //}
    }
  }
}

.allSlotsWrapper
{
  display: flex;
  flex-direction: column;

  &.leadership
  {
    gap: 10px;

    @media (min-width: @siteBreakPointMobileMax)
    {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

// ---------------------------------------

.positionHeader
{
  position: relative;
  align-items: center;
  padding: 11px 15px 7px 15px;
  min-height: 33px;

  font-size: 12px;
  line-height: 15px;
  color: @colorTextGray;

  //&:before
  //{
  //  content: '';
  //  position: absolute;
  //  top: 50%;
  //  left: 15px;
  //  width: 6px;
  //  height: 6px;
  //  margin-top: -2px;
  //
  //  border-radius: 50%;
  //  background: #c4c4c4;
  //}
}

// ---------------------------------------

.blankState
{
  min-height: 75px;
  margin: 10px;
  background: linear-gradient(0deg, #f5f8fa, #f5f8fa), #fff;
}

// ---------------------------------------

.staffWrapper
{

}

.staff
{
  display: flex;
  flex-direction: column;
  flex: 1;

  //margin: 0 5px; // TODO

  //&.isLeaving,
  //&.joiningCandidate
  &.withBorder
  {
    //border: 1px solid #2965cc;
    border: 1px solid #8a9ba8;
    border-radius: 3px;
    margin: 2px 5px 8px;

    .staffNameWrapper
    {
      padding-left: 9px;
    }

    .staffDate
    {
      padding-left: 23px - 6;
    }
  }

  &.borderBlue
  {
    border-color: #2965cc;
  }

  &.borderOrange
  {
    border-color: #f5903d;
  }

  &.unitedTeamLead
  {
    .staffNameWrapper
    {
      //align-self: flex-start;
      //background: #fcc;
    }

    &.withBorder
    {
      .staffNameWrapper
      {
        //align-self: auto;
      }
    }

    .staffName
    {
      white-space: nowrap;
    }
  }
}

.leadership
{
  .staffWrapper
  {
    display: flex;
    flex: 1;
    //flex-direction: column;

    max-width: 100%;
    min-width: 100%;

    @media (min-width: @siteBreakPointMobileMax) // 768
    {
      min-width: calc((100% - 30px) / 4);
      max-width: calc((100% - 30px) / 4);
    }

    @media (min-width: @breakPointMacBookProDefault) // 1440
    {
      min-width: calc((100% - 50px) / 6);
      max-width: calc((100% - 50px) / 6);
    }
  }

  .staff
  {
    margin-top: 3px;

    @media (min-width: @siteBreakPointMobileMax) // 768
    {
      align-self: flex-start;
    }

    &.withBorder
    {
      margin-top: 2px;
    }
  }
}

// ---------------------------------------

.staffNameWrapper
{
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  min-height: 35px;
  max-width: 100%;
  padding: 7px 10px 8px 15px;

  &:hover
  {
    cursor: default;
    text-decoration: none;
  }

  &.isLink
  {
    &:hover
    {
      cursor: pointer;

      .staffName
      {
        color: @colorLink;
      }
    }
  }
}

.staffName
{
  padding: 1px 0;
  line-height: 18px;
  color: @colorText;

  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar
{
  margin-right: 10px;
}

// ---------------------------------------

.staffDate
{
  display: flex;
  align-items: center;

  padding-left: 23px;
  margin-bottom: 8px;

  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;

  &.noDateSet
  {
    font-size: 14px;
    color: #eb8787;
  }
}

.dateIcon
{
  margin-right: 10px;

  &.gray
  {
    color: @colorTextGray;
  }

  &.green
  {
    color: @colorSlotGreen;
  }

  &.blue
  {
    color: @colorSlotBlue;
  }

  &.orange
  {
    color: @colorSlotOrange;
  }
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.teamWrapper
{
  display: flex;
  flex-direction: column;
}

// ---------------------------------------

.positions
{
  display: flex;
  flex-direction: column;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  border-top: @borderGray15;

  transition: margin .2s ease-out, border-top-color .2s ease-out;

  &.selected
  {
    margin: 9px 0;
    border-top-color: transparent;
  }

  &.selected + .wrapper
  {
    border-top-color: transparent;

    .inner
    {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  &.isOpened
  {
    margin: 9px 0;
    border-top-color: transparent;

    .inner
    {
      &:after
      {
        transition: border-bottom-color .2s ease-out;
        border-bottom-color: @colorGray15;
      }
    }

    &.selected
    {
      &:before
      {
      }
    }
  }

  &.selected
  {
    position: relative;

    &:before
    {
      pointer-events: none;
      content: '';
      position: absolute;
      z-index: 1;
      top: -1px;
      left: 0;
      right: 0;
      bottom: -1px;
      border-radius: 3px;
      border: 1px solid @colorLink;
    }

    .inner
    {
      border-radius: 3px;
    }

    &.isOpened
    {
      .inner
      {
        border-radius: 3px 3px 0 0;
      }
    }
  }

  &:last-child
  {
    .inner
    {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.wrapper.isOpened + .wrapper
{
  border-top-color: transparent;
}

// ---------------------------------------

.inner
{
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;

  background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;

  &:after
  {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color .2s .1s ease-out;
  }

  &:hover
  {
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.3), rgba(191, 204, 214, 0.3)), #fff;

    &.canOpen
    {
      cursor: pointer;
    }

    .btnDownIcon
    {
      color: @colorLink;
    }
  }
}

.inDialog
{
  .inner
  {
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.3), rgba(191, 204, 214, 0.3)), #fff;

    &:hover
    {
      background: linear-gradient(0deg, rgba(191, 204, 214, 0.5), rgba(191, 204, 214, 0.5)), #fff;

      .iconRadio:not(.isSelected)
      {
        box-shadow: inset 0 0 0 1px @colorLink;
      }
    }
  }
}

.iconRadioForPositionDialogItem
{
  margin-left: 10px;
}

// ---------------------------------------

.templateIconWrapper
{
  flex: 0;
  padding: 0 10px;
}

.templateIcon
{
  margin-left: -1px;
  margin-right: 1px;
  color: #f5903d;

  &.isReady
  {
    margin-left: 0;
    margin-right: 0;
    color: @colorLink;
  }
}

.customIcon
{

}

.iconRadio
{
  min-width: 16px;
  width: 16px;
  height: 16px;

  border-radius: 50%;
  //border: 1px solid @colorGray20;
  //background: #f5f8fa;

  border: none;
  box-shadow: inset 0 0 0 1px #738091;
  background-clip: padding-box;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(17, 20, 24, 0), rgba(17, 20, 24, 0.05));

  &.isSelected
  {
    position: relative;

    //border: none;
    //background: linear-gradient(360deg, rgba(255, 255, 255, 1e-05) 0%, rgba(255, 255, 255, 0.1) 100%), #2965cc;
    //box-shadow: inset 0px -1px 1px @colorGray20;, inset 0px 0px 0px 1px rgba(16, 22, 26, 0.4);

    background-color: #2d72d2;
    background-image: linear-gradient(to bottom, rgba(17, 20, 24, 0), rgba(17, 20, 24, 0.05));
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, .2);

    &:before
    {
      content: '';
      position: absolute;
      //width: 6px;
      //height: 6px;
      //top: 5px;
      //left: 5px;
      //background: #fff;
      //border-radius: 50%;

      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    }
  }
}

// ---------------------------------------

.positionName
{
  flex: 1;
  padding: 11px 20px 11px 0;
  font-weight: 500;
  color: @colorText;
}

// ---------------------------------------

.actionsBlock
{
  min-width: 150px - 12;
}

.hiddenBlock
{
}

.inDialog
{
  .hiddenBlock
  {
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.3), rgba(191, 204, 214, 0.3)), #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

// ---------------------------------------

.arrowWrapper
{
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 40px;

  display: flex;
  align-items: center;

}

.collapseButton
{
  margin-left: 3px;

  &:hover
  {
    background: none !important;
  }

  .btnDownIcon
  {
    color: @colorTextLightGray;
  }
}

// ---------------------------------------

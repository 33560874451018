@import '/src/styles/variables';

// ---------------------------------------

.header
{
  //border-bottom: 1px solid @colorBorder;
  border-radius: 3px 3px 0 0;

  background: @colorHeaderBg;
}

.listWrapper
{
  //overflow-y: auto;
}

.noTeams
{
  margin-top: 10px;
}

.noPositions
{
  min-height: 75px;
}

// ---------------------------------------

.positionsWrapper
{
  background: #ebf1f5;
}

// ---------------------------------------

.item
{
  //background-color: #E1E8ED !important;
}

.isManagedTeam
{
  background: @colorLink;

  .isManagedTeamChevronIcon
  {
    color: #fff !important;
  }

  &:hover
  {
    .isManagedTeamChevronIcon
    {
      color: #fff !important;
    }
  }
}

// ---------------------------------------

.subItemsWrapper
{
  padding: 0 10px 11px 36px;
}

.subItemsHeader
{
  display: flex;
  align-items: center;

  min-height: 40px;
  padding: 6px 10px 0 36px;

  font-size: 12px;
  line-height: 18px;
  color: @colorTextGray;
}

.colTemplate
{
  padding: 0 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @teamsListWidth_Name;
  }
}

.colStaffRole
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
  }
}

// ---------------------------------------

.spinnerWrapper
{
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

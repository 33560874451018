@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

// ---------------------------------------

.header
{
  display: flex;
  align-items: center;
  min-height: 41px;

  background: #fff;
  border-bottom: @borderGray15;
  border-radius: 3px 3px 0 0;
}

.isLoading
{
  position: relative;
  overflow-y: hidden;
}

.noItemsTitle
{
  margin-top: 10px;
}

// ---------------------------------------

@minHeightItem: 36px + 9*2 + 1; // 55 with border = 54 between borders

.item
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: @minHeightItem;

  background: #fff;
  border-bottom: @borderGray15;

  &:last-child
  {
    border-bottom: none;
    min-height: @minHeightItem - 1; // - top border
  }
}

// ---------------------------------------

.spinnerWrapper
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}

@import '/src/styles/variables';

// ---------------------------------------

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 50px;
  padding: 0 15px;

  border-radius: 3px 3px 0 0;
  background: @colorHeaderBg;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding: 0 25px;
  }
}

.headerTitle
{
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: @colorText;

  &:last-child
  {
    padding-right: 0;
  }
}

.col1
{
  width: 20%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.col2
{
  width: 20%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.col3
{
  width: 20%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.col4
{
  width: 20%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.col5
{
  margin-left: auto;
}

// ---------------------------------------

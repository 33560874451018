@import "/src/styles/variables";

// ---------------------------------------

.priorityCellWrapper
{
  display: flex;
  flex-direction: column;

  &.empty
  {
    //min-height: 55px;
    transform: translateY(100%);
  }

  &:last-child
  {
    .priorityCellView
    {
      border-radius: 0 0 3px 3px;
    }

    .priorityCellView.isExpanded
    {
      border-radius: 0;
    }

    .priorityCellView:not(.isExpanded)
    {
      min-height: 55px;
      border-bottom: none;
    }

    .priorityCellView.isEditMode
    {
      margin-bottom: -1px;
      min-height: 56px;
      border-Bottom: 1px solid @colorLink;
    }
  }
}

// ---------------------------------------

.priorityCellView
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 56px;
  padding-left: 15px;

  border-bottom: @borderGray15;
  background: #fff;

  &.isEditMode
  {
    padding-left: 14px;
    border-left: 1px solid @colorLink;
    border-right: 1px solid @colorLink;
  }
}

// ---------------------------------------


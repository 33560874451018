@import "/src/styles/variables";

// ---------------------------------------

.layout
{
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 0;
}

.left,
.right
{
  flex: 1;
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
}

.left
{
  margin-right: 12px;
}

.right
{
  margin-left: 12px;
  //padding-top: 50px;
}

// ---------------------------------------

.btnRecruiterCall
{
  margin: 10px 0;
  background: #fff !important;
}

// ---------------------------------------

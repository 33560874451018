@import '/src/styles/variables';

// ---------------------------------------

.dialogOverlay
{
  background: @colorBgOverlay;
}

.dialogWrapper
{
  position: relative;
  width: @dialogWidth_Small;
  max-width: calc(100% - 20px);

  border-radius: 6px;
  box-shadow: none;
  //background: #ebf1f5;
  background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
}

.confirmDialog
{
  //width: 440px; // default = @dialogWidth_Small
}

// ---------------------------------------

.dialog
{
  padding: 20px 20px 0;
}

.dialogTitle
{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.dialogTitleText
{
  font-size: 18px;
  font-weight: 900;
  line-height: 21px;

  letter-spacing: 1px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.titleAdditionalContent
{
  flex: 1;
  margin-left: 20px;
}

.dialogTitleDivider
{
  margin: 0 -20px 20px;
  height: 1px;
  background: @colorGray10;
}

.btnClose
{
  display: none;

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: block;
    position: absolute;
    top: (30px - 16) / -2;
    right: -30px;
  }

  &:hover
  {
    background: none !important;
  }
}

.btnCloseIcon
{
  color: #fff !important;
}

// ---------------------------------------

.dialogFooter
{
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex-shrink: 0;
  margin-top: 25px;
  padding-bottom: 10px;

  .dialogBtn
  {
    min-width: 125px;
    line-height: 20px;

    & + .dialogBtn
    {
      margin-left: 12px;
    }
  }
}

// ---------------------------------------

.dialogTitleDivider + .labelOnSingleLine
{
  margin-top: -5px;
}

.warningMessage + .row
{
  margin-top: 30px;
}

.row
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 40px;

  &.labelOnSingleLine
  {
    flex-direction: column;

    .label
    {
      width: 100%;
      margin-right: 0;
      padding: 0;
    }

    .inputWrapper
    {
      margin-top: 10px;
    }

  }

  & + .row
  {
    margin-top: 10px;
  }

  & + .labelOnSingleLine
  {
    margin-top: 15px;
  }

  .label
  {
    align-self: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    width: 70px;
    margin-right: 10px;
    padding: 11px 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .inputWrapper
  {
    //display: flex;
    //align-items: center;
    //flex-grow: 1;
    //flex-shrink: 1;
    width: 100%;
    min-width: 0;
    //min-height: 40px;

    label // .bp4-control
    {
      margin-bottom: 0;
    }

    .dialogTextInput();
  }
}

// ---------------------------------------

.selectBtn
{
  .dialogInputStyle();

  width: 100%;

  //height: 40px;    // size={ButtonSize.LARGE}
  //font-size: 16px; // size={ButtonSize.LARGE}
  line-height: 20px; // !!!

  padding-left: 10px !important;
  padding-right: 10px !important;

  .buttonTextEllipsis();

  &.disabled
  {
    color: @colorTextGray;
    cursor: default;

    //:global .bp4-icon
    //{
    //  display: none;
    //}

    &:hover
    {
      color: @colorTextGray;
    }
  }
}

// ---------------------------------------

.warningMessage
{
  display: flex;
  align-items: center;
  margin-top: 20px;

  font-weight: 500;
}

.iconInfo
{
  margin-right: 10px;
  color: @colorOrange;
}

// ---------------------------------------

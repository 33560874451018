@import "/src/styles/variables";

// ---------------------------------------

.positionsButtonWrapper
{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 72px;
  margin-left: 10px;

  .positionsButton
  {
    color: @colorTextGray;

    &:hover
    {
      color: @colorTextGray;
      background: rgba(191, 204, 214, 0.2) !important;
    }

    .positionsButtonIcon
    {
      color: @colorTextLightGray;
    }
  }

  .positionsButtonIcon
  {
    color: @colorTextLightGray;
  }

  :global .bp4-active
  {
    color: @colorTextGray !important;
    background: rgba(191, 204, 214, 0.2) !important;

    .bp4-icon
    {
      color: @colorTextLightGray;
    }
  }
}

.positionsButtonPopover // = bp4-overlay
{
  :global .bp4-popover
  {
    margin-top: 2px !important;
  }
}

.positionsButtonMenu
{
  min-width: 150px;
}

// ---------------------------------------

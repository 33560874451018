@import '/src/styles/variables';

// ---------------------------------------

.subHeader
{
  margin-bottom: 20px;
  font-weight: 500;
}

// ---------------------------------------

.selectedUser
{
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  font-weight: 500;
  line-height: 15px;
}

.avatar
{
  margin-right: 15px;
}

// ---------------------------------------

.bgGray
{
  margin: 0 -@fullscreenPageSidebarPadding 20px;
  padding: 11px @fullscreenPageSidebarPadding 13px;

  font-weight: 500;

  background: #f5f8fa;
}

.inlineEditorWrapper
{
  margin-left: -10px;
  margin-right: -10px;
}

.textWithIcon
{
  display: flex;
}

.textIcon
{
  margin-right: 11px;
  color: @colorTextGray;
}

.text
{
  color: @colorTextGray;
}

// ---------------------------------------

.dateTime
{
  // margin-bottom: 11px;

  display: flex;
  align-items: center;
  font-weight: 500;
  color: @colorText;

  width: 100%;
}

.users
{
  margin-bottom: 23px;
}

.user
{
  color: @colorTextGray;
}

// ---------------------------------------

.smallTextBg,
.smallText
{
  font-size: 11px;
  line-height: 15px;
  color: @colorTextGray;
}

.smallTextBg
{
  margin-top: 8px;
  margin-bottom: 20px;
  padding: 3px 10px;

  background: linear-gradient(0deg, #ebf1f5, #ebf1f5), #fff;
  border-radius: 3px;
}

.joinGoogleMeet
{
  margin-top: (30px - 16) / 2;
  margin-bottom: 20px;
}

.btnJoinGoogleMeet
{
  margin-top: (16px - 30) / 2;
  margin-bottom: 5px;

  color: @colorLink !important;
  background: rgba(41, 101, 204, 0.2) !important;
  box-shadow: none !important;
}

// ---------------------------------------

.description
{
  color: @colorTextGray;

  &.empty
  {
    color: #c4c4c4;
  }
}

// ---------------------------------------

.link
{
  align-self: flex-start;
  color: @colorTextGray;
  margin: 0 10px;

  .icon
  {
    vertical-align: top;
    margin-right: 7px;

    &.drive
    {
      margin-top: 1px;
    }
  }

  &:not(.isDisabled) &:hover
  {
    text-decoration: none;
    color: @colorLink;
  }

  + .link
  {
    margin-top: 15px;
  }
}

// ---------------------------------------

.divider
{
  margin: 15px -@fullscreenPageSidebarPadding;
  min-height: 1px;
  background: #e5e5e5;
}

.spacer
{
  margin-top: auto;
}

// ---------------------------------------

.btnScheduleCall
{
  margin-bottom: 10px;
  margin-top: 20px;
}

// ---------------------------------------

.checkbox
{
  margin-top: 15px;
  margin-bottom: 0;
}

// ---------------------------------------

.readyWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 40px;
  margin-bottom: 25px;
  padding: 10px 15px;

  background: #ebf1f5;
  border-radius: 3px;
}

.switchReady
{
  margin: 0;
  white-space: nowrap;
}

// ---------------------------------------

.iconField
{
  &.isChanged
  {
    color: @colorOrange;
  }
}

// ---------------------------------------

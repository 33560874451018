@import '/src/styles/variables';

@widthInputClosed: 240px;
@widthInputOpened: 340px;

.positionDialogItemWrapper
{
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top: @borderGray15;
  display: flex;
  background: @colorSubItemBg;
  align-items: center;
  height: 40px;

  &.isSelected
  {
    margin: 10px 0;
    border: 1px solid @colorLink !important;
    border-radius: 3px;
  }

  &.isNextSelected
  {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.isSelected + .positionDialogItemWrapper,
.positionDialogItemWrapper:nth-child(2)
{
  border-top: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.positionDialogItemWrapper:last-child
{
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.positionDialogItemWrapper:hover
{
  cursor: pointer;
  background: @colorPositionDialogItemBackground;
}

.highlight
{
  color: #000;
  background: #d8e1e8;
}

.roleTitle
{
  padding-left: 10px;
  font-weight: 500;
  color: @colorText;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@import '/src/styles/variables';

.toolbar
{
  .btnArrowLeft
  {
    margin-left: -8px;
    margin-right: 8px;
  }

  .title
  {
    margin-right: 20px;

    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1px;
    color: @colorText;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      font-size: 18px;
    }
  }
}

.secondRow
{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: none;
  }
}

.btnAdd
{
  white-space: nowrap;

  @media (max-width: @siteBreakPointTabletMin)
  {
    display: none;
  }
}

.menuButton
{
  display: flex;

  @media (min-width: @siteBreakPointTabletMin)
  {
    display: none;
  }
}

.activeProspectiveSwitcher
{
  z-index: 0;

  &.mobile
  {
    @media (min-width: @siteBreakPointTabletMin)
    {
      display: none;
    }
  }

  &.desktop
  {
    @media (max-width: @siteBreakPointTabletMin)
    {
      display: none;
    }
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.iframe
{
  flex: 1;
}

// ---------------------------------------

@import "/src/styles/variables";
@import "PrioritiesListByCommon.module";

@headerHeight: 40px;
@cellHeight: 60px;

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  min-height: 0;
  overflow-y: auto;

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: none;
  }
}

// ---------------------------------------

.columnPriority
{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  &:last-child
  {
    margin-bottom: 0;
  }
}

// ---------------------------------------

.priorityHeader
{
  display: flex;
  align-items: center;

  min-height: 50px;
  padding: 0 10px;

  color: @colorText;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  background: linear-gradient(0deg, #e1e8ed, #e1e8ed), #fff;
  border-radius: 3px 3px 0 0;

  &.isEditMode
  {
    background: @colorLink;
  }
}

.userInHeader
{
  padding-left: 5px;
}

// ---------------------------------------


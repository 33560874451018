@import "/src/styles/variables";

// ---------------------------------------

.actionsBlock
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.proposedUserAvatars
{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 90px;
  min-height: 40px;
  margin-right: 42px;
}

.proposedUserAvatar
{
  margin: 0 2px 0 3px;
}

// ---------------------------------------

.hiddenBlock
{
  padding: 10px;
}

// ---------------------------------------

.slotsWrapper
{
}

// ---------------------------------------

.allSlotsAreBusy
{
  padding: 9px;

  color: @colorTextLightGray;
  background: @colorBgGray;
  border: 1px solid @colorBorderGray;
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon
  {
    margin-right: 10px;
  }
}

// ---------------------------------------

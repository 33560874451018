@import "/src/styles/variables";

// ---------------------------------------

.positionGroupSelect
{
  .popoverWrapper();

  :global
  {
    .bp4-menu
    {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.toolbar
{
  flex-wrap: wrap;
  padding-top: 14px;
  padding-bottom: 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
  @media (min-width: 1280px)
  {
    flex-wrap: nowrap;
    padding: 0;
  }
}

.positionLeft
{
  min-width: 100%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    min-width: 40%;
  }
}

.positionCenter
{
  min-width: 100%;
  margin-top: 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin: 0;
    min-width: 20%;
  }
}

.positionRight
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    min-width: 40%;
  }
}

// ---------------------------------------

.btnAdd
{
  white-space: nowrap;
  margin-right: 20px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
  }
}

// ---------------------------------------

.buttonGroup
{

}

.buttonGroupBtn
{
  min-width: 115px;
}

// ---------------------------------------

.viewMobile
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none !important; // popover2
  }
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.scrolled
{
  overflow-y: auto;
}

// ---------------------------------------

.blankState
{
  margin-top: 10px;
}

.listWrapper
{
  background: #fff;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.toolbar
{
}

.positionLeft
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }

  @media (width: 320px)
  {
    min-width: 40%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }
}

.positionCenter
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 20%;
  }

  @media (width: 320px)
  {
    min-width: 20%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 20%;
  }
}

.positionRight
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }

  @media (width: 320px)
  {
    min-width: 40%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }
}

// ---------------------------------------


// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.mobile,
.desktop,
.wrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wrapper
{
  margin-top: -22px;

  @media (max-width: @siteBreakPointMobileMax)
  {
    margin-top: 15px;
  }
}

// ---------------------------------------

.mobile,
.desktop
{
  display: none;
}

.mobile
{
  @media (max-width: @siteBreakPointMobileMax - 1)
  {
    display: flex;
  }
}

.desktop
{
  @media (min-width: @siteBreakPointMobileMax)
  {
    display: flex;
  }
}

// ---------------------------------------

.btn
{
  color: @colorLink !important;
  font-weight: 500;

  &:first-child
  {
    margin-left: 0;
  }

  &:hover,
  &.isCurrent
  {
    background: rgba(41, 101, 204, 0.1) !important;
  }

  &.isDisabled
  {
    color: #8a9ba8 !important;

    &:hover
    {
      cursor: default !important;
      background: none !important;
    }
  }

  &.isEllipsis
  {
    max-width: 24px;
    margin-left: 0;
    margin-right: -7px;
  }

}

// ---------------------------------------

.mobile,
.desktop,
.btn
{
  margin-left: 7px;

  @media (max-width: @breakPointIPhonePlus)
  {
    margin-left: 5px;

    &.isEllipsis
    {
      margin-right: -5px;
    }
  }

  @media (max-width: @breakPointIPhone5Se)
  {
    margin-left: 0;

    &.isEllipsis
    {
      margin-right: 0;
    }
  }
}

.btn
{
  @media (max-width: @breakPointIPhone5Se)
  {
    padding: 0 5px !important; // default: 0 7px
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.content
{
  margin: 4px 0 -6px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.userWrapper
{
  flex-shrink: 1;
  display: flex;
  align-items: center;
  min-width: 0;

  &:nth-child(odd)
  {
    //background: #faa;
  }
}


.userAvatar
{
  margin-right: 15px;
}

.textWrapper
{
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
}

.userName
{
  margin-bottom: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: @colorText;
  font-size: 14px;
}

.userRole
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}

// ---------------------------------------

.isEditMode
{
  .userName,
  .userRole
  {
    color: #fff;
  }
}

// ---------------------------------------

@import "/src/styles/variables";
@import "PrioritiesListByCommon.module";

// ---------------------------------------

.user
{
  min-height: 50px; // overwrite desktop 60
  min-width: 150px;
  max-width: 150px;
  padding-left: 16px;
}

// ---------------------------------------

.btnEditArrowWrapper
{
  display: flex;
  align-self: stretch;
  width: 46px;

  &:last-child
  {
    margin-right: 2px;
  }
}

.btnEditArrow
{
  flex: 1;
}

.btnEditMorePopover
{
  display: flex !important; // !! overwrite popover2 styles
  align-self: stretch;
  width: 48px;
  margin-left: auto;
}

.btnEditMore
{
  flex: 1;
}

// ---------------------------------------


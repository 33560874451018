@import "/src/styles/variables";

// ---------------------------------------

.spinnerWrapper
{
  flex: 1;
}


// ---------------------------------------

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.listWrapper2
{
  height: 100%;
}

.headerWrapper
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
    min-height: 50px;
    background-color: @colorHeaderBg;
  }
}

.header
{
  flex: 1;
  display: flex;
  align-self: stretch;

  font-size: 14px;
  font-weight: 500;

  .col
  {
    display: flex;
    padding-left: 0;

    .notOrderableTitle
    {
      padding-left: 10px;
      line-height: 16px;
    }
  }
}

.rowWrapper
{
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.col
{
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  min-width: 0;

  &:last-child
  {
    padding: 0;
  }
}

@fullColsWidth: 1168;

.colName
{
  min-width: 230 / 394 * 100%;
  max-width: 230 / 394 * 100%;
  padding-left: 16px;
  min-height: 58px;

  &.colNameHeader
  {
    padding-left: 75px;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    min-width: 320 / @fullColsWidth * 100%;
    max-width: 320 / @fullColsWidth * 100%;
    min-height: unset;
    padding-left: 24px;
  }
}

.header
{
  .colName
  {
    padding-left: 75px - 10; // btnOrder padding
  }
}

.colDateCreated
{
  display: flex;
  flex-wrap: wrap;
  //font-size: 12px;
  //font-family: 'Roboto Mono', monospace;
}

.dateCreated,
.timeCreated
{
  white-space: nowrap;
}

.dateCreated
{
  margin-right: 10px;
}

.colPositionGroups
{
}

.colStatus
{
  display: block;
  flex-direction: column;
  align-items: start;
  min-width: 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-direction: row;
    align-items: center;
  }
}

.colFlowStage
{
  flex-direction: column;
  align-items: start;
}

.colAssignedTo
{
  flex: 1;

  &.ellipsis
  {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.colCreatedBy
{
  flex: 1;
}

.colSiteSubsite
{
  flex: 1;
}

.colMore
{
  flex: 0;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  min-width: 36px;
  margin-left: auto;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    min-width: 54px;
  }
}

// ---------------------------------------

.pgMulti
{
  display: flex;
  flex: 1;
  min-width: 0;
}

.pgMultiName
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pgMultiCounter
{
  color: @colorTextGray;
}

// ---------------------------------------

.avatar
{
  margin-right: 15px;
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
  }
}

.avatarMobile
{
  margin-right: 8px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

// ---------------------------------------

.name
{
  padding: 11px 0;
  font-size: 14px;
  color: @colorText;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding: 0;
  }
}

.btnMore
{
  flex-grow: 1;
}

// ---------------------------------------

.mobile
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

.desktop
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
  }
}

.mobileAssignedTo,
.mobilePositionGroups
{
  margin-top: 4px;
  font-size: 11px;
  color: @colorTextLightLightGray;
}

.mobileAssignedTo
{
  &.ellipsis
  {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// ---------------------------------------

.btnStaffed
{
  color: @colorLink !important;
  font-weight: 500;
  margin-left: -10px;
}

@import '/src/styles/variables';

// ---------------------------------------

.customer
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  min-height: 40px;
  padding-left: 16px;

  cursor: pointer;

  background: rgba(245, 248, 250, 0.5);
  border-bottom: 1px solid @colorBorder;

  font-size: 14px;
  line-height: 18px;

  &:last-child
  {
    border-bottom: none;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-wrap: nowrap;

    padding-left: 25px;
  }

  &:hover
  {
    background: #ebf1f5;

    .customerName
    {
      color: @colorLink;
    }
  }

}

// ---------------------------------------

.customerAlias,
.customerName
{
  align-self: flex-start;
  flex-grow: 0;
  flex-shrink: 1;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;
  margin: 11px 0;
  padding-right: 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  &.desktop
  {
    display: none;
  }

  &.mobile
  {
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @customerListWidth_Name;
    margin: 11px 0;

    &.desktop
    {
      display: block;
    }

    &.mobile
    {
      display: none;
    }
  }
}

.customerAlias
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @customerListWidth_Alias;
  }
}

// ---------------------------------------

.positions
{
  flex-grow: 0;
  flex-shrink: 1;
  order: 3;

  margin: 11px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 2;
    width: @customerListWidth_Total;
    min-width: @customerListWidth_TotalMin;
    padding-right: 10px;
  }
}

// ---------------------------------------

.teamsWrapper
{
  display: flex;
  flex: 1;
  min-width: 0;
  order: 2;

  flex-direction: column;
  padding-right: 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 3;
    flex-direction: row;
    align-items: center;
  }
}

.teams
{
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  margin: -6px 0 11px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin: 11px 0;
  }
}

.team
{
  display: flex;
  align-items: center;

  line-height: 18px;
  white-space: nowrap;

  &.prospective
  {
    color: @colorTextLightGray;
  }

  &:after
  {
    margin: 1px 5px -1px;
    content: '\00b7';
    font-size: 28px;
  }

  &:last-child:after
  {
    content: none;
  }
}

// ---------------------------------------

.menu
{
  order: 4;
  align-self: flex-start;
  margin-top: 0;
  margin-right: 0;
}

.menuBtn
{
  min-width: 48px;
  min-height: 40px;
}

// ---------------------------------------

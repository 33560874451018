@import '/src/styles/variables';

// ---------------------------------------

.select
{
  .popoverWrapper();
}

.menuItem
{
  .menuItemLargeStyle();
}

.iconWarning
{
  color: @colorOrange !important;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.positions
{
  display: flex;
  align-items: center;
  min-height: 18px;

  .positionsIcon
  {
    color: @colorTextLightGray;
  }

  .positionCount
  {
    width: 20px;

    text-align: right;
  }

  .positionSeparator
  {
    width: 10px;

    text-align: center;
  }

  .positionTotalCount
  {
    width: 20px;

    text-align: left;

    &.hideCount
    {
      text-align: right;
    }
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  min-height: 0;
  padding: 20px;
  margin-top: 11px;

  @media (min-width: @siteBreakPointTabletMax)
  {
    margin-top: 24px;
  }
}

.image
{
  max-width: 100%;
}

.title
{
  font-weight: 900;
  font-size: 22px;
  line-height: 21px;
  letter-spacing: .5px;
}

.message
{
  margin-top: 10px;
}

.button
{
  margin-top: 35px;
}

// ---------------------------------------

@import "/src/styles/variables";

@colWidth: 100% / 6; // user + 5 priorities

// ---------------------------------------

.wrapper
{
  display: none;
  flex-direction: column;

  //background: #fff;

  &.isEditMode
  {
    .header
    {
      background: @colorLink;
    }

    .headerTitle
    {
      color: #fff;
    }
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: flex;
    flex: 1;
    min-height: 0;
  }
}

// ---------------------------------------

.rowsWrapper
{
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  min-height: 0;
  overflow-y: auto;

  background: #fff;
}

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 50px;

  background: linear-gradient(0deg, #e1e8ed, #e1e8ed), #fff;
  border-radius: 3px 3px 0 0;

  .colWrapper
  {
    //background: #fcc;
  }
}

.headerTitle
{
  //background: #faa;
  font-weight: 500;
  white-space: nowrap;

  &.isUser
  {
    padding-left: 15px;
  }
}

// ---------------------------------------

.dragWrapper
{
  background: #faa;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

// ---------------------------------------

.row
{
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 56px;

  //gap: 10px; // (x) !!! drag transform
  //margin: 0 -5px;

  border-bottom: @borderGray15;
}

.spacer
{
  //flex: 1;
  //width: 100%;
  //min-width: 0;
  //background: #fcc;
}

.dropRow
{
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  //box-shadow: 0 0 0 1px rgba(0,0,255,.5) inset;
}

// ---------------------------------------

.colWrapper
{
  //background: #fee;
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  flex: 1;
  min-width: @colWidth;
  max-width: @colWidth;

  padding: 0 15px;

  // TODO: debug -> remove !
  //box-shadow: 0 0 0 1px rgba(255,255,255,1) inset, 0 0 0 2px rgba(255,0,0,.5) inset;
  //box-shadow: 0 0 0 1px rgba(255,0,0,.5) inset;

  &:first-child
  {
    padding-left: 10px;
  }

  &:last-child
  {
    padding-right: 10px;
  }

  &.isCanDrop
  {
    position: relative;

    &:before
    {
      content: '';
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      bottom: 0;

      background: rgba(191, 204, 214, 0.15);
    }
  }

  &.isDragOver
  {
    position: relative;

    &:after
    {
      content: '';
      position: absolute;
      top: 5px;
      left: 10px;
      right: 10px;
      bottom: 5px;

      background: #e1e8ed;
      border: 1px dashed rgba(138, 155, 168, 0.7);
      border-radius: 3px;
    }

    &.isDropOverError
    {
      &:after
      {
        background: none;
        border-color: rgba(255, 0, 0, .5);
      }
    }
  }

  &.isDragging
  {
    background: none;
    min-width: auto;

    &:before
    {
      display: none;
    }
  }

  &.btnAdd
  {
    z-index: 0;
  }
}

// ---------------------------------------

.user
{
  padding-left: 14px;
}

// ---------------------------------------

@import '/src/styles/variables';

.cardsWrapper
{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  min-width: 0;
  //min-width: 180px;
  margin: 0 5px;
  padding-top: 10px;
  padding-bottom: 10px;

  &.isDragging
  {
    background: @colorDragTargetBg;
  }

  &.isDraggingOver
  {
    position: relative;

    &:before
    {
      position: absolute;
      z-index: 1;
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;

      content: ' ';

      border: 1px dashed @colorDropTargetBg;
      border-radius: 3px;
      background: @colorGroupBg;
    }
  }
}

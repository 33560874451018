@import "/src/styles/variables";

// ---------------------------------------

.content
{
  margin: 4px 0 -6px;
}

.checkbox
{

}

// ---------------------------------------

.wrapper
{
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
}

.btn
{
  width: 100%;
  height: 100%;
}

// ---------------------------------------

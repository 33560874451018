@import '/src/styles/variables';

// ---------------------------------------

@keyframes expand
{
  from
  {
    max-height: 0;
    opacity: 0;
  }
  to
  {
    max-height: 100%;
    opacity: 1;
  }
}

// ---------------------------------------

//.form
//{
//  &.animate
//  {
//    animation-duration: 0.42s;
//    animation-name: expand;
//  }
//
//  display: flex;
//  flex-direction: column;
//
//  //background: #fff;
//  //box-shadow: 0 0 10px @colorGray15;
//
//  //margin-bottom: 10px;
//
//  // overflow: auto;
//}

// ---------------------------------------

.collapseListWrapper
{
  margin-bottom: 10px;

  &.animate
  {
    animation-duration: 0.42s;
    animation-name: expand;
  }
}

.collapseListItemWrapper
{
  margin: 0 !important;

  background: #fff;
  box-shadow: 0 0 10px @colorGray15;
}

.collapseListItem
{
  cursor: pointer;

  //min-height: 38px;
  //border-radius: 3px !important;
  //background: none !important;
  background: @candidateFormHeaderBg !important;
  border: 0 !important;

  &:hover
  {
    //color: @colorLinkHover;
  }
}

.collapseListItemContent
{
  //margin: 0 9px;
}

.collapseListBtn
{
  margin-left: 10px;
  //padding-top: 11px;
  color: @colorTextGray !important;
  background: unset !important;
  //background: @candidateFormHeaderBg !important;
  opacity: .6;
}

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  //height: 40px;
  padding: 0 0 0 1px;

  background: @candidateFormHeaderBg;

  .title
  {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .btn
  {
    flex-grow: 0;
    flex-shrink: 0;

    min-width: 40px;
    height: 40px;

    & + .btn
    {
      margin-left: 10px;
    }
  }

  .spacer
  {
    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;
  }
}

.content
{
  padding: 10px 20px 0 10px;
  display: flex;
  flex-direction: column;

  .summary
  {
    font-weight: 500;
    color: @colorText;
  }

  .timeRow
  {
    font-weight: 500;
  }

  .timeRow, .participantsRow, .descriptionRow, .linkRow
  {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon
    {
      padding: 1px;
    }

    .participants, .description
    {
      width: 100%;
    }
  }

  .linkRow
  {
    padding-left: 10px;
    gap: 9px;
    font-size: 12px;
    color: @colorTextGray;
  }
}

.participantsEditorText
{
  white-space: normal;
}

.actionBar
{
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btnSelect
  {
    min-height: 24px;
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
  }

  .btnSave
  {
    min-width: 130px;
    margin-left: 10px;
  }
}

// ---------------------------------------

.userItem
{
  display: flex;
  flex-direction: row;
  align-items: center;

  .userAvatar
  {
    margin-right: 10px;
  }

  .userDetails
  {
    max-width: 180px;

    .userName
    {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .userEmail
    {
      font-size: 12px;
      line-height: 15px;
      color: @colorTextGray06;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.emptyEmail
{
  display: flex;
  align-items: center;
  color: @colorOrange;
}

.iconWarning
{
  margin-right: 5px;
  margin-bottom: 2px;
}

// ---------------------------------------

.iconField
{
  &.isChanged
  {
    color: @colorOrange;
  }
}

// ---------------------------------------

.dateTimeEditor, .startTimeEditor, .endTimeEditor
{
  margin-right: 10px;
  color: @colorText;

  &.readonly
  {
    color: @colorTextGray;
  }
}

//.tag
//{
//  &:first-child, &:nth-child(2)
//  {
//    svg // hides "remove" icon
//    {
//      display: none;
//    }
//  }
//}

.startTimePopover
{
  max-height: 215px;
  overflow: auto;
}

.timeInput
{
  width: 80px;
}

.duration
{
  font-weight: 400;
  color: @colorTextLightGray;
  padding-left: 10px;

  &.isNegative
  {
    color: @colorSlotOrange;
  }
}

.btnManageCall
{
  color: @colorLink !important;
  margin-left: 16px;
  margin-right: auto;
}

.btnRevertChanges
{
  min-width: 130px;
  color: @colorLink !important;
}

.participantsText
{
  margin: 6px 0;
  line-height: 20px;
}

.footer
{
  min-height: 62px;
  background-color: @colorBgFormSection;
  border-top: @borderGray15;
  padding: 10px 20px;

  display: flex;
  align-items: center;

  .callCompleted
  {
    margin-top: 10px;
  }
}

.menu
{
  width: 40px;
}

.scheduledByLabel
{
  color: @candidateCommunicationActiveTabBackground;
}

.googleMeetBtn
{
  :global .bp4-icon
  {
    color: @colorLink !important;
  }

  margin-bottom: 3px;
}

@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  display: flex;
  flex: 1;

  width: 100%;
  padding: 0 10px;
  overflow-y: auto;
}

.content
{
  padding-top: 10px;
}

// ---------------------------------------

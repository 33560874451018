@import "/src/styles/variables";

// ---------------------------------------

.toolbar
{
}

.positionLeft
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 30%;
  }

  @media (width: 320px)
  {
    min-width: 10%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
      min-width: 50%;
  }
}

.positionCenter
{
  &.isEditMode
  {
    display: none;
  }

  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }

  @media (width: 320px)
  {
    min-width: 40%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: none;
  }
}

.positionRight
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 30%;
  }

  @media (width: 320px)
  {
    min-width: 100px;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 50%;
  }
}

// ---------------------------------------

.toolbarBtn
{
  display: none;
  min-width: 99px;
  margin-left: 10px;

  &:last-child
  {
    //min-width: 100px;
  }

  white-space: nowrap;

  &.mobileViewByUsers
  {
    display: flex;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: flex;
    min-width: 125px;
  }
}

// .mobileFilterBtn
// {
//   min-width: 60px !important;

//   @media (width: 320px)
//   {
//     min-width: 50px !important;
//   }
// }

.mobileFilterBtn
{
  min-width: 45px !important;

  &.isEditMode
  {
    display: none;
  }

  @media (width: 320px)
  {
    //min-width: 50px !important;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    display: none;
  }
}


// ---------------------------------------

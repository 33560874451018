@import '/src/styles/variables';

.desktop
{
  display: none;

  @media (max-width: @siteBreakPointDesktopMin)
  {
    display: block;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  @media (max-width: @breakPointIPhonePlus)
  {
    //
  }
}

// ---------------------------------------

.positionLeft,
.positionCenter,
.positionRight
{
  display: flex;
  flex-direction: row;
  align-items: center;
}

// ---------------------------------------

.menuButton
{
  margin: 0;
}

.buttonText
{
  @media (max-width: @breakPointIPhonePlus)
  {
    display: none;
  }
}

// ---------------------------------------

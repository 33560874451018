@import "/src/styles/variables";

// ---------------------------------------

.btn
{
  font-weight: 500;

  &:hover
  {
    background: @colorBgOrderButton !important;
    mix-blend-mode: multiply;
  }
}

.colName
{
  cursor: default;
}

.btnIcon
{
  color: @colorIcons !important;
}

// ---------------------------------------


@import '/src/styles/variables';

// ---------------------------------------

.newCustomerDialog
{
  display: flex;
  flex-direction: column;

  width: @dialogWidth_Default;
}

// ---------------------------------------

.notes
{
  resize: vertical;
  min-height: 64px;
}

.radio
{
  margin-top: 11px;

  &:last-child
  {
    margin-bottom: 11px !important;
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  align-self: stretch;
  //margin-left: auto;
}

.btnMore
{
  flex: 1 0;
  opacity: .6;

  //background: #fcc !important;
  background: none !important;

  &:focus,
  &:hover
  {
    position: relative;
    z-index: 1;
    background: none !important;
    opacity: 1;
  }
}

.btnMoreIcon
{
  color: @colorIcons !important;
}

.isVertical
{
  opacity: .6;

  &:hover
  {
    opacity: 1;
  }

  .btnMoreIcon
  {
    transform: rotate(90deg);
  }
}

// ---------------------------------------


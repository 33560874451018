@import '/src/styles/variables';

.colTitles
{
  position: relative;

  display: flex;

  flex-grow: 0;
  flex-shrink: 0;

  height: 35px;
  padding: 0 5px;

  white-space: nowrap;
  // background: @colorHeaderLightBg2;

  border-radius: 3px 3px 0 0;

  &:after
  {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 1px;
    left: 0;

    height: 1px;

    content: ' ';

    .grayShadow;
  }

  .col
  {
    position: relative;

    display: flex;
    align-items: center;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    //min-width: 180px;

    padding: 0 12px;

    & + .col:before
    {
      position: absolute;
      left: 0;

      height: 14px;

      content: ' ';

      border-left: solid 1px @colorBorder;
    }

    .colTitle
    {
      color: @colorText08;

      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
    }

    @media (max-width: @siteBreakPointDesktopMin)
    {
      //min-width: 180px;
    }
  }
}

@import '/src/styles/variables';

.btn
{
  user-select: none;

  &.btnFlow
  {
    // overwrite BP button styles here
  }

  //&.WARNING
  //{
  //  color: white !important;
  //  border: none !important;
  //  border-radius: 3px;
  //  background: linear-gradient(360deg, rgba(255, 255, 255, 1e-05) 0%, rgba(255, 255, 255, 0.1) 100%), #D9822B;
  //  box-shadow: inset 0 -1px 1px @colorGray20 !important;
  //}
}

@import '/src/styles/variables';

.scrollableX
{
  display: flex;
  overflow: hidden;
  overflow-x: auto;

  flex-grow: 1;

  width: 100%;
}

.scrollableY
{
  overflow-y: auto;

  flex-grow: 1;
}

.wrapper
{
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;

  min-width: 0;
  min-height: 0;
  margin-top: 12px;

  .groupsWrapper
  {
    overflow-x: hidden;
    overflow-y: auto;

    flex-grow: 1;
    flex-shrink: 1;

    min-height: 0;

    //@media (max-width: @siteBreakPointTabletMax)
    //{
    //  overflow-y: none;
    //}
  }
}

// ---------------------------------------

.flowTitle
{
  margin-bottom: 8px;

  color: #000000;

  font-size: 14px;
  font-weight: 500;
}

.weekTitle
{
  margin-bottom: 8px;

  color: #000000;

  font-size: 14px;
}

.customerTitle
{
  margin-bottom: 20px;
  padding-bottom: 4px;

  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  font-size: 14px;
}

.positionGroupTitle
{
  margin-top: 16px;
  margin-bottom: 10px;

  color: #000000;

  font-size: 14px;
  font-weight: 500;
}

// ---------------------------------------

.colTitles,
.colCards
{
  display: flex;
  flex-direction: row;

  margin-right: -7px;
  margin-left: -7px;
}

.colTitles
{
  margin-bottom: 10px;
}

.colCards
{
  margin-bottom: 20px;
}

.col
{
  display: flex;
  flex-direction: column;

  width: calc(100% / 6);
  padding-left: 7px;

  &:last-child
  {
    .cards
    {
      border: none;
    }
  }
}



.cards
{
  flex: 1;

  border-right: 1px solid rgba(0, 0, 0, 0.2);

  //background: #fcc;
}

.card
{
  min-height: 106px;
  margin-top: 5px;
  margin-right: 7px;
  padding: 9px 12px;

  border-radius: 3px;
  background: #FFFFFF;
  box-shadow: 0 0 0 @colorGray15;

  &:first-child
  {
    margin-top: 0;
  }
}

.cardTitle
{
  color: #000000;

  font-size: 14px;
  line-height: 18px;
}

.cardPosition
{
  margin-top: 2px;

  color: rgba(0, 0, 0, 0.5);

  font-size: 12px;
  line-height: 15px;
}

.cardDate
{
  margin-top: 8px;

  color: #000000;

  font-size: 12px;
  line-height: 15px;

  .cardIcon
  {
    margin-right: 5px;
    margin-bottom: 1px;
  }
}

.cardIcon
{
  color: @colorIcons;
}

.cardCustomer
{
  margin-top: 8px;

  color: rgba(0, 0, 0, 0.5);

  font-size: 12px;
  line-height: 15px;
}

// ---------------------------------------

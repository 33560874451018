@import '/src/styles/variables';

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 50px;
  padding-left: 18px;

  //border-bottom: 1px solid @colorBorder;
  border-radius: 3px 3px 0 0;

  background: @colorHeaderBg;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding-left: 25px;
  }

  .headerTitle
  {
    color: @colorText;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}

// ---------------------------------------

.name
{
  width: 100%;

  .desktop
  {
    display: none;
  }

  .mobile
  {
    flex: 1;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @customerListWidth_Name;

    .desktop
    {
      display: block;
    }

    .mobile
    {
      display: none;
    }
  }
}

.alias
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
    width: @customerListWidth_Alias;
    margin-right: 0;
  }
}

.total
{
  margin-right: 33px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @customerListWidth_Total;
    min-width: @customerListWidth_TotalMin;
    margin-right: 0;
  }
}

.teams
{
  flex: 1;
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
  }
}

// ---------------------------------------

.internal
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 40px;

  .headerTitle
  {
    margin-top: 6px;

    color: @colorTextGray;
    font-size: 12px;
    line-height: 15px;
  }
}

// ---------------------------------------

@import "/src/styles/variables";

.card
{
  display: flex;
  flex-direction: column;

  position: relative;

  width: 383px;
  min-height: 191px;

  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;

  @media (max-width: @siteBreakPointMobileMax)
  {
    max-width: 100%;
    min-width: 100%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: calc(50% - 5px);
    max-width: calc(50% - 5px);
  }

  @media (min-width: @breakPointMacBookProDefault)
  {
    // gap = 10px, 3 items; decrease width of each item by (10 * 2) / 3
    min-width: calc(33.33% - 6.66px);
    max-width: calc(33.33% - 6.66px);
  }

  @media (min-width: @breakPointWideDisplay)
  {
    // gap = 30px, 3 items; decrease width of each item by (10 * 3) / 4
    min-width: calc(25% - 7.5px);
    max-width: calc(25% - 7.5px);
  }

  .header
  {
    display: flex;
    width: 100%;
    height: 50px;

    padding: 15px 25px;

    font-weight: 500;

    color: @colorText;
    background: @colorGroupBg;
    border-bottom: @borderGray15
  }

  .contentWrapper
  {
    padding: 15px 25px;
    display: flex;
    flex-direction: row;

    .iconWrapper
    {
      width: 32px;
      height: 32px;

      padding: 7px 8px;

      background-color: @colorBlue3;
      border-radius: 50%;

      color: #fff;
    }

    .content
    {
      display: flex;
      width: 100%;
      padding-right: 27px;
      margin-left: 15px;
    }
  }

  .buttonBar
  {
    width: 100%;
    display: flex;
    position: absolute;

    bottom: 0;
    padding: 15px 25px 30px;

    .button
    {
      min-width: 125px;
      margin-left: auto;
    }
  }
}

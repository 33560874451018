@import '/src/styles/variables';

// ---------------------------------------

.dialog
{
}

// ---------------------------------------

.inputWrapper
{
  padding-left: 26px;
}

// ---------------------------------------

.radioWrapper
{
  min-height: 18px;
}

.inputWrapper
{
  + .radioWrapper
  {
    margin-top: 20px;
  }
}

.radio
{
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

// ---------------------------------------

.selectSavedFilterBtn
{
  width: 100%;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.select
{
  .popoverWrapper();
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.spinnerWrapper
{
  flex: 1;
}

// ---------------------------------------

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.headerWrapper
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
    min-height: 50px;
    background-color: @colorHeaderBg;
  }
}

.header
{
  flex: 1;
  display: flex;
  align-self: stretch;

  font-size: 14px;
  font-weight: 500;

  .col
  {
    padding-left: 0;
  }
}

.rowWrapper
{
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  min-width: 0;
  cursor: pointer;
}

.col
{
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  min-width: 0;

  &:last-child
  {
    padding: 0;
  }
}

@fullColsWidth: 1173 + 1; // design

@colPositionsPlusStatusMinus: 40;

@colStaffName: 296;
@colStaffPositions: 364 + @colPositionsPlusStatusMinus;
@colStaffStatus: 292 - @colPositionsPlusStatusMinus;
@colStaffSiteSubsite: 175;

.colWidth(@width)
{
  min-width: @width / @fullColsWidth * 100%;
  max-width: @width / @fullColsWidth * 100%;
}

.colName
{
  min-width: 230 / 394 * 100%;
  max-width: 230 / 394 * 100%;
  padding-left: 16px;
  min-height: 58px;

  &.colNameHeader
  {
    padding-left: 75px;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    .colWidth(@colStaffName);
    //min-width: @colStaffName / @fullColsWidth * 100%;
    //max-width: @colStaffName / @fullColsWidth * 100%;
    min-height: unset;
    padding-left: 24px;
  }
}

.header
{
  .colName
  {
    padding-left: 75px - 10; // btnOrder padding
  }
}

.colCustomer
{
  //min-width: 0;
  //flex-wrap: wrap;
  //padding-top: 9px;
  //padding-bottom: 9px;

  //min-width: 410 / @fullColsWidth * 100%;
  //max-width: 410 / @fullColsWidth * 100%;

  .colWidth(@colStaffPositions);

  @media (max-width: @siteBreakPointTabletMin)
  {
    // min-width: unset;
    display: none;
  }
}

.colStatus
{
  flex-direction: column;
  align-items: start;
  justify-content: center;
  //padding-top: 9px;
  //padding-bottom: 9px;

  // min-width: 220px;

   @media (min-width: @siteBreakPointDesktopMin)
   {
     .colWidth(@colStaffStatus);
     //min-width: 230 / @fullColsWidth * 100%;
     //max-width: 230 / @fullColsWidth * 100%;
  //   flex-direction: row;
  //   align-items: center;
   }

  @media (max-width: @siteBreakPointTabletMax)
  {
    // min-width: unset;
    display: none;
  }
}

.colSiteSubsite
{
  // min-width: 180px;
  //max-width: 180 / (1440 - 220) * 100%;
  .colWidth(@colStaffSiteSubsite);

  @media (max-width: @siteBreakPointTabletMax)
  {
    // min-width: unset;
    display: none;
  }
}

.colMore
{
  flex: 0;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  min-width: 36px;
  margin-left: auto;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    min-width: 16px + 30px;
  }
}

// ---------------------------------------

.avatar
{
  margin-right: 15px;
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
  }
}

.avatarMobile
{
  margin-right: 8px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

// ---------------------------------------

.name
{
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.userName
{
  font-size: 14px;
  line-height: 20px;
  color: @colorText;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roleName
{
  min-height: 15px;
  margin-top: 3px;
  font-size: 12px;
  line-height: 15px;
  color: rgba(24, 32, 38, 0.6);
}

.btnMore
{
  flex-grow: 1;
}

// ---------------------------------------

.mobile
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

.desktop
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: flex;
  }
}

.mobileAssignedTo,
.mobilePositionGroups
{
  margin-top: 4px;
  font-size: 11px;
  color: @colorTextLightLightGray;
}

// ---------------------------------------

.slotWrapper
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1;
  min-width: 0;
}

.slot
{
  display: flex;
  align-items: center;
  max-width: 100%;

  margin-top: 4px;

  &:first-child
  {
    margin-top: 0;
  }

  .positionName
  {
    display: flex;
    align-items: center;
    min-width: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.slotTeam,
.slotCustomer
{
  font-size: 14px;
  font-weight: 500;
  color: @colorText;
  white-space: nowrap;
}

.slotCustomer
{

}

.slotTeam
{

}

.slotPosition
{
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: @colorTextGray;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slotsCount
{
  padding-left: 10px;
  color: @colorTextGray;
}

.iconChevron
{
  margin: 0 4px;
}

.iconDot
{
  margin: 0 7px;
  color: @colorIcons;
}

.iconDateDot
{
  margin: 0 7px 0 3px;
  color: @colorIcons;
}

// ---------------------------------------

//.wrapItemStatus,
//.wrapItemStatusDate
//{
//  display: flex;
//  align-items: center;
//  min-width: 0;
//}

//.wrapItemStatus
//{
//  flex-shrink: 0;
//}

.status
{
  display: flex;
  align-items: center;

  //min-height: 36px;
  margin-top: 4px;

  max-width: 100%;

  &:first-child
  {
    margin-top: 0;
  }

  &.newcomer
  {
    .statusDateIcon
    {
      color: @colorLink;
    }
  }
  &.staffed
  {
    .statusDateIcon
    {
    }
  }
  &.leaving
  {
    .statusDateIcon
    {
      color: @colorOrange;
    }
  }
  &.archived
  {
    .statusDateIcon
    {
      color: @colorTextLightGray;
    }
  }
  &.warning
  {
    .statusDateIcon
    {
      color: @colorOrange;
    }
  }
}

//.iconStatusDot
//{
//  margin-right: 6px;
//
//  &.CurrentStaff
//  {
//    color: #0d8050;
//  }
//
//  &.LeavingStaff
//  {
//    color: #8a9ba8;
//  }
//}

.statusTitle
{
  white-space: nowrap;
  font-size: 14px;
  color: @colorText;
}

//.statusDate
//{
//  overflow: hidden;
//  text-overflow: ellipsis;
//
//  font-size: 14px;
//  color: @colorTextGray;
//  white-space: nowrap;
//}

.hireDate,
.leavingDate
{
  margin-left: 15px;

  white-space: nowrap;
  line-height: 16px;
}

.statusDateIcon
{
  margin-right: 10px;
}

// ---------------------------------------


.positionGroupPill
{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;

  white-space: nowrap;

  height: 25px;
  padding: 0 12px 1px;
  margin: 3px 5px 2px 0;

  background: linear-gradient(0deg, rgba(19, 124, 189, 0.2), rgba(19, 124, 189, 0.2)), #fff;
  border-radius: 20px;
}

.icon
{
  margin-left: 5px;
}

@import "/src/styles/variables";

@userPhotoWidth: 128px;

// ---------------------------------------

.panel
{

}

// ---------------------------------------

.userInfo
{
  display: flex;
  flex-direction: row;

  padding: 20px 20px 18px 10px;
}

.left
{
  flex: 1;
  min-width: 0;
}

.right
{
  width: @userPhotoWidth;
  margin-left: 20px;
}

// ---------------------------------------

.photo
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0;

  min-width: @userPhotoWidth;
  min-height: @userPhotoWidth;
  overflow: hidden;

  background: #e1e8ed;
  border-radius: 8px;
}

.photoImg
{
  object-fit: cover;
  width: 100%;
}

// ---------------------------------------

.secondNameIcon
{
  color: rgba(92, 112, 128, 0.4);
}

.cvInlineEditor
{
  padding-left: 0;
}

.cvIconClassName
{
  margin-left: 10px;
}

.iconLinkedIn
{
  color: #0077b7;
}

.phoneWrapper
{
  flex: 1;
  flex-basis: 45%;
  min-width: 174px;
}

.siteWrapper
{
  flex: 1;
  flex-basis: 55%;
}

.viewCV
{
  color: rgba(16, 107, 163, 1);
  font-weight: 500;

  &:hover
  {
    text-decoration: none;
  }
}

.attachCV
{
  color: rgba(92, 112, 128, 0.5);
  cursor: default;
  padding-left: 10px;
}

// ---------------------------------------

.moreInfoWrapper
{
  margin-left: auto;
  margin-top: -50px;
  padding: 0 20px 19px;
}

.btnMoreDetails
{
  color: @colorLink;
}

.btnMoreDetailsPlus
{
  color: @colorTextLightGray;
}

// ---------------------------------------

.addInfoBtn
{
  margin-top: 25px;
  margin-bottom: 2px;
  border-radius: 3px;
  border: none;
  color: @colorTextLightGray !important;
  background: @colorMoreInfoPillBgBlue !important;

  &:hover
  {
    cursor: pointer;
    color: @colorLink !important;
    background: @colorCommunicationTitleBgBlue !important;

    .addInfoBtnIcon
    {
      color: @colorLink !important;
    }
  }
}

.addInfoBtnIcon
{
  color: @colorTextLightGray !important;
}

// ---------------------------------------

.addInfoDropDown
{
  margin-left: 9px;

  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-left: 1px !important;
      margin-top: 1px !important;
    }
  }
}

// ---------------------------------------

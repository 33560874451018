@import '/src/styles/variables';

// ---------------------------------------

.tabs
{
  //height: 100%;
  position: sticky;
  top: 61px;
  gap: 10px;
  z-index: 2;
  margin-top: -1px;

  margin-bottom: 10px;

  :global .bp4-tab-indicator-wrapper
  {
    display: none;
  }

  :global .bp4-tab-list
  {
    //position: sticky;
    //top: 61px;
    gap: 10px;
    //z-index: 2;
    margin-left: -10px;
    margin-right: -10px;
    padding: 11px 10px 5px;

    background: #fff;
    border-bottom: @borderGray15;
  }

  :global .bp4-tab
  {
    flex: unset;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;

    min-height: 24px;
    max-height: 24px;
    min-width: 50px;

    font-size: 14px;
    font-weight: 500;
    color: @colorText;

    background-color: @colorPageBackground !important;
    border-radius: 3px;

    // override some bp4 props
    margin-top: 0 !important;
    margin-right: 0 !important;
    box-shadow: unset !important;

    & + .bp4-tab
    {
      margin-left: 0;
    }

    &:hover
    {
      min-height: 24px;
      max-height: 24px;
      cursor: pointer;
      user-select: none;
      color: @colorLink;
    }

    &.active
    {
      min-height: 24px;
      max-height: 24px;
      //background: #fff;
      z-index: 1;

      color: white;
      background-color: @candidateCommunicationActiveTabBackground !important;

      &:hover
      {
        cursor: default;
      }
    }
  }

  :global .bp4-tab-panel
  {
    margin-top: 10px;
    //height: calc(100% - 29px);
  }

  .historyTab
  {
    min-width: auto;
  }
}

// ---------------------------------------

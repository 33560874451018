@import 'var_colors';
@import 'var_media';
@import 'var_fonts';
@import 'var_text';

// ---------------------------------------

@zindexSidebar: 100;
@zindexPageHeader: 1000;
@zindexDialog: 10000;
@zindexBPPortal: 10001;

// ---------------------------------------

@appHeaderHeight: 50px;

@fullscreenPageSidebarPadding: 15px;

// ---------------------------------------

@dialogWidth_Default: 440px;
@dialogWidth_Small: @dialogWidth_Default;
@dialogWidth_Large: 590px;

.dialogInputBorder()
{
  box-shadow: none;
  border: 1px solid @colorGray20;
}

// ---------------------------------------

@borderRadius: 5px;

@collapseListItemsBorderRadius: 3px;

// ---------------------------------------

.dialogInputFocusStyle()
{
  border-color: @colorLink;
  box-shadow: 0 0 0 2px rgba(45, 114, 210, .3) !important;

  outline: none;
  //border-color: transparent;
  //box-shadow: inset 0 0 0 1px #2d72d2,
  // 0 0 0 2px rgba(45, 114, 210, .3),
  // inset 0 1px 1px rgba(17, 20, 24, .2) !important;
}

.dialogInputStyle()
{
  background: #fff !important;
  border-radius: 3px;
  border: 1px solid @colorGray20;
  box-shadow: none !important;

  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  //border: none;
  //box-shadow: inset 0 0 0 1px @colorGray20 !important;

  &:hover
  {
    border-color: @colorGray30;
    box-shadow: 0 0 0 2px @colorGray05 !important;
  }

  &:focus
  {
    .dialogInputFocusStyle();
  }
}

.dialogTextInput()
{
  :global .bp4-input
  {
    .dialogInputStyle();
  }

  :global(.bp4-input.bp4-active)
  {
    .dialogInputFocusStyle();
  }

  :global .bp4-input::placeholder
  {
    color: rgba(24, 32, 38, 0.5);
  }

  //:global .bp4-input:focus
  //{
  //}
}

// ---------------------------------------

.boxShadow()
{
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
}

// ---------------------------------------

.menuItemLargeStyle()
{
  padding: 10px 11px;
  font-size: 16px;
  line-height: 20px;
}

// ---------------------------------------

// bp4-popover-wrapper
//
// - bp4-popover-target bp4-popover-open
//   - bp4-input-group
//     - bp4-icon
//     - bp4-input
//
// - bp4-overlay
//   - bp4-transition-container
//     - bp4-popover
//       - bp4popover-content

// popoverProps={{
//   minimal: true,        // remove top arrow
//   usePortal: false,
//   className: ...        // = bp4-popover-wrapper
//   targetClassName: ...  // = bp4-popover-target
//   portalClassName: ...  // = bp4-overlay
//   popoverClassName: ... // = bp4-popover
// }}

.popoverWrapper() // = bp4-popover-wrapper = popoverProps.className: ...
{
  position: relative;
  flex: 1; // inside DialogRow -> .inputWrapper

  :global
  {
    .bp4-popover-target // = popoverProps.targetClassName: ...
    {
      display: block; // if forget -> fill: true
    }

    //.bp4-overlay // = popoverProps.portalClassName: ...
    //{
    //}

    .bp4-transition-container
    {
      width: 100%;
    }

    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      width: 100%;
      margin-top: 3px !important;
    }
  }
}

// ---------------------------------------

.textEllipsis()
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonTextEllipsis()
{
  :global .bp4-button-text
  {
    .textEllipsis()
  }
}

// ---------------------------------------

.menuItemStyle()
{
  &.isMenuDivider
  {
    position: relative;
    margin-bottom: 11px;

    &:after
    {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -6px;
      height: 1px;
      background: @colorMenuDivider;
    }
  }
}

// ---------------------------------------

@customerListWidth_Name: (250 / 1170) * 100%;
@customerListWidth_Alias: (250 / 1170) * 100%;
@customerListWidth_Total: (180 / 1170) * 100%;
@customerListWidth_TotalMin: 110px;

// ---------------------------------------

@teamsListBaseWidth: 1168px;
@teamsListWidthPercentage: 1 / @teamsListBaseWidth * 100%;

@teamsListWidth_Name: 400px * @teamsListWidthPercentage;
@teamsListWidth_Alias: 278px * @teamsListWidthPercentage;

// ---------------------------------------

@positionsListBaseWidth: 1168px - 50;
@positionsListWidthPercentage: 1 / @positionsListBaseWidth * 100%;

@positionsListWidth_PositionTemplate: 373px * @positionsListWidthPercentage;
@positionsListWidth_StaffRole: 318px * @positionsListWidthPercentage;
@positionsListWidth_StaffedTotal: 120px * @positionsListWidthPercentage;

// ---------------------------------------

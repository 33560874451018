@import '/src/styles/variables';

.toolbar
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  // margin-top: 15px;

  max-width: calc(100vw - 20px);

  min-height: 30px;
  padding: 10px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    // flex-direction: row;
  }
}

// ---------------------------------------

.positionLeft
{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;

  @media (min-width: @siteBreakPointTabletMin)
  {
    width: 35%;
  }
}

.positionCenter
{
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: @siteBreakPointTabletMin)
  {
    width: 0;
  }

  @media (max-width: @siteBreakPointDesktopMin)
  {
    //margin: 10px 0;
  }
}

.positionRight
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: @siteBreakPointTabletMin)
  {
    width: 65%;
  }

  @media (max-width: @siteBreakPointDesktopMin)
  {
    //margin: 10px 0;
  }

  & > *
  {
    //margin-left: 5px;
    margin: 5px 0 5px 5px;
  }
}

// ---------------------------------------

.filtersWrapper
{
  display: flex;
  flex-direction: column;
}

.filters
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &._old
  {
    & > *
    {
      //margin-left: 5px;
      margin: 5px 0 5px 5px;
    }
  }
}

.filterComponentWrapper
{
  margin: 0 0 0 5px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.actionsWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  min-height: 40px;
  margin: 10px 0;

  color: @colorTextGray;
}

// ---------------------------------------

.status
{
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.statusIcon
{
  margin-right: 10px;
}

.iconDot
{
  margin: 0 7px;
}

.hireDate
{

}

// ---------------------------------------

.btnMore
{
  margin-right: -10px;
  margin-left: 16px;
}

// ---------------------------------------

@import "/src/styles/variables";

@userPhotoWidth: 128px;

// ---------------------------------------

.panel
{
  flex: 1;
}

.btnAddWrapper
{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: -10px;
}

.btnAdd
{
  font-size: 14px;
  color: @colorTextGray !important;
}

// ---------------------------------------

.userInfo
{
  display: flex;
  flex-direction: row;

  padding: 20px 20px 18px 10px;
}

.left
{
  flex: 1;
  min-width: 0;
}

.right
{
  width: @userPhotoWidth;
  margin-left: 20px;
}

// ---------------------------------------

.photo
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0;

  min-width: @userPhotoWidth;
  min-height: @userPhotoWidth;
  overflow: hidden;

  background: #e1e8ed;
  border-radius: 8px;
}

.photoImg
{
  object-fit: cover;
  width: 100%;
}

// ---------------------------------------

.secondNameIcon
{
  color: rgba(92, 112, 128, 0.4);
}

.cvInlineEditor
{
  padding-left: 0;
}

.cvIconClassName
{
  margin-left: 10px;
}

.iconLinkedIn
{
  color: #0077B7;
}

//.phoneAndSite
//{
//  display: flex;
//  flex-direction: row;
//  flex-wrap: wrap;
//  align-items: flex-start;
//  margin-top: -2px;
//}

.phoneWrapper
{
  flex: 1;
  flex-basis: 45%;
  min-width: 174px;
}

.siteWrapper
{
  flex: 1;
  flex-basis: 55%;
}

.viewCV
{
  color: rgba(16, 107, 163, 1);
}

.attachCV
{
  color: rgba(92, 112, 128, 0.5);
}

.errorIcon
{
  margin-left: 5px;
  margin-bottom: 1px;
  color: @colorOrange;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.slotWrapper
{
  border-radius: 3px;

  &.isOpenSlot
  {
    margin-bottom: 5px;

    &:last-child
    {
      margin-bottom: 0;
    }
  }

  &.isOpenSlot:not(.isSelected)
  {
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.5), rgba(191, 204, 214, 0.5)), #fff;
  }

  &.isOpenSlot.singleSlot,
  &.isSelected
  {
    background: linear-gradient(0deg, rgba(41, 101, 204, 0.2), rgba(41, 101, 204, 0.2)), #fff;
  }

  &.singleSlot
  {

  }

  &.withBorder
  {
    background: none;
    border: 1px solid @colorGray20;

    .line
    {
      padding-left: 9px;
      padding-right: 4px;
    }

    &.orange
    {
      border-color: @colorSlotOrange;
    }

    &.blue
    {
      border-color: @colorLink;
    }

    //&.gray
    //{
    //  border-color: @colorGray20;
    //}
  }
}

// ---------------------------------------

.line
{
  display: flex;
  flex-direction: row;

  padding: 0 5px 0 10px;

  &.inDialog
  {
    cursor: pointer;
  }

  &.isSelected
  {
    cursor: default;
  }

  &:hover
  {
    .radioIcon:not(.isSelected)
    {
      color: @colorLink;
    }
  }
}

// ---------------------------------------

.radioIcon
{
  margin-top: 12px;
  margin-right: 10px;
  color: @colorGray20;

  &.isSelected
  {
    color: @colorLink;
  }
}

// ---------------------------------------

.nameWrapper
{
  display: flex;
  align-items: center;
  min-width: 0;

  padding: 11px 0;
  line-height: 18px;

  &.reverse11
  {
    //background: #afa;
  }
  &.reverse12
  {
    //background: #cfc;
  }
  &.reverse21
  {
    //background: #aaf;
  }
  &.reverse22
  {
    //background: #ccf;
  }
}

.nameAndAvatar
{
  display: flex;
  align-items: center;
  min-width: 0;

  &.isLink
  {
    text-decoration: none;
    color: @colorText;

    &:hover
    {
      color: @colorLink;
    }
  }
}

.nameText
{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.textBold
  {
    font-weight: 600;
  }

  &.isOpenPosition
  {
    color: @colorTextGray;
  }
}

.error
{
  color: #f00;
}

.arrowIcon
{
  margin: 0 10px;
  color: @colorTextLightGray;
}

// ---------------------------------------

.dates
{
  //display: flex;
  //flex-direction: column;
  //margin-left: auto;
}

.dateLines
{
  display: flex;
  flex-direction: column;
  min-width: 192px + 5;
  margin-left: auto;
}

.dateLine
{
  display: flex;
  align-items: center;
  min-width: 192px + 5;
  min-height: 40px;
  margin-left: auto;
  //background: #fcc;

  &.dateLine2
  {
    min-height: 30px;
    margin-top: -5px;
    margin-bottom: 5px;
    //background: #faa;
  }

  .btnEditDate
  {
    display: flex;
    flex-shrink: 0;
    //margin-right: 32px;
    line-height: 18px;

    &.selectedDate
    {
      color: @colorText;
    }

    &.emptyLeavingDate
    {
      flex: 1;
      margin-right: 0;
    }

    .btnEditDateIcon
    {
      margin-right: 11px;
      color: @colorLink !important;

      &.showClockIcon
      {
        color: @colorTextLightGray !important;
      }
    }
  }

  .btnEditDateBtn
  {
    //flex: 1;
  }

  .btnEditDate
  {
    .iconWarning
    {
      color: #f5903d !important;
    }
  }
}

.daysWorked
{
  margin-left: 10px;
  font-size: 12px;
  line-height: 18px;
  color: @colorTextGray;
}

// ---------------------------------------

.startOrLeavingDateText
{
  margin-left: 10px;

  &.isEmpty
  {
    color: @colorSlotOrange;
  }
}

.iconStartDate,
.iconLeavingDate
{
  margin-left: 10px;
  margin-right: 1px;
}

.iconStartDate
{
  color: @colorLink;
}

.iconClock
{
  color: @colorTextLightGray;
}

.iconLeavingDate
{
  color: @colorSlotOrange;
}

.iconWarning
{
  margin-left: auto;
  margin-right: 9px;
  color: #f5903d !important;
}

.tooltipWrapper
{
  margin-left: auto;
  //margin-right: 10px;
}

.leavingDateTooltip
{
  text-align: center;
}

// ---------------------------------------

.dateInput
{
  flex: 1;
  //margin-left: 3px; // (x) !!!
  padding-left: 3px;

  :global .bp4-input
  {
    max-width: 197px - 3; // = 197 - 3 padding-left
    padding-left: 34px !important;

    box-shadow: inset 0 0 0 1px @colorLink;
    border-radius: 2px;
  }

  .dateInputPopoverTarget
  {
    display: block;
  }
}

.dateInputIcon
{
  margin: ((30px - 16) / 2) 9px 0 0;
}

.dateInputIconWarning
{
  color: #f5903d;
}

.dateInputIconOk
{
  color: @colorLink;
}

// ---------------------------------------

.slotLinesWrapper
{
  flex: 1;
  min-width: 0;
}

.slotLine
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .avatar
  {
    margin: -1px 10px -1px 0;
  }
}

.lineN
{
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 0 5px;
  margin: 0;
  margin-right: 5px;
  background: #fcc;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
}

// ---------------------------------------

.proposedCandidates
{
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 0 16px 8px 26px;

  border-top: @borderGray15;
}

.proposedHeader
{
  padding: 12px 0 6px;
  font-size: 12px;
  color: @colorTextGray;
}

.proposedCandidate
{
  display: flex;
}

.proposedCandidateLink
{
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-height: 35px;
  min-width: 0;

  color: @colorText;

  .avatar
  {
    margin-right: 10px;
  }

  .name
  {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover
  {
    text-decoration: none;
    color: @colorLink;

    .avatar
    {
      color: @colorText;
    }
  }
}

// ---------------------------------------

.proposedCount
{
  margin: -6px 0 -6px 4px;
  flex-shrink: 0;
}

.proposedCountIcon
{
  color: @colorTextLightGray !important;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.actionsBlock
{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  //padding-right: 42px;
  //background: #ccf;

  &.isProposed
  {
    .btnPropose
    {
      display: none;
      //visibility: hidden;
    }
  }

  &.isProposedByMe
  {
    .btnPropose
    {
    }
  }

  &.isOpened:not(.isProposedByMe)
  {
    .proposedUserAvatars
    {
      display: none;
    }
  }

  &.isOpened.isProposed:not(.isProposedByMe)
  {
    .btnPropose
    {
      display: inline-flex !important;
      color: @colorLink !important;
      border-color: @colorLink !important;
    }
  }
}

.btnPropose
{
  min-width: 90px;
  margin-right: 42px;

  color: @colorTextLightGray !important;

  border-radius: 15px;
  border-color: transparent !important;
}

.proposedUserAvatars
{
  //position: absolute;
  //top: 0;
  //right: 42px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 90px;
  min-height: 40px;
  margin-right: 42px;

  //background: #fcc;
}

.proposedUserAvatar
{
  margin: 0 2px 0 3px;
}

// ---------------------------------------

.positionItem
{
  &:hover
  {
    .actionsBlock:not(.isProposedByMe)
    {
      .proposedUserAvatars
      {
        display: none;
      }
    }

    .btnPropose
    {
      display: inline-flex;
      //visibility: visible;
      color: @colorLink !important;
      border-color: @colorLink !important;

      &:hover
      {
        background: linear-gradient(0deg, rgba(41, 101, 204, 0.15), rgba(41, 101, 204, 0.15)), #fff !important;
      }
    }
  }
}

// ---------------------------------------

.hiddenBlock
{
  background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
}

// ---------------------------------------

.note
{
  padding: 0 10px;

  &:last-child
  {
    .noteContent
    {
      padding-bottom: 16px;
    }
  }

  &:first-child
  {
    .noteContent
    {
      //padding-bottom: 0;
    }
  }
}

.noteHeader
{
  display: flex;
  align-items: center;
  min-height: 41px;
  padding-top: 1px;
}

.noteAvatar
{
  margin-right: 10px;
}

.proposedBy
{
  flex: 1;
  padding-top: 2px;
  font-size: 12px;
  color: @colorTextGray;
}

// ---------------------------------------

.noteContent
{
  margin-top: -3px;
  padding-left: 36px - 10;
  padding-bottom: 4px;

  white-space: pre-wrap;
  overflow-wrap: break-word;

  color: @colorTextGray;
}

.notesEditor
{
  margin: -9px 0 -6px -10px;
}

.notesEditorEdit
{
  margin-left: -10px;
}
.notesEditorView
{
}
.notesEditorInput
{
  min-height: 66px;
  padding: 6px 10px !important;
}

// ---------------------------------------

.btnAddNote
{
  margin-right: 6px;

  &.editNote
  {
    margin-right: 8px;
  }
}

.btnMoreNote
{
  margin-right: -1px;
  transform: translateX(.5px);
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

// ---------------------------------------

.headerContent
{
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

// ---------------------------------------

.buttons
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}

.btnAllocation
{
  margin-left: 10px;
  color: @colorLink !important;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;

  &.isDisabled
  {
    opacity: .5;
  }
}

.btnTodoRemove
{
  color: #fff !important;

  &:global .bp4-active
  {

  }
}

// ---------------------------------------

.pgEditor
{
  width: calc(100% + 20px);
  margin: 0 -10px;
}

.viewClassName
{
}

.editClassName
{
}

.textBefore
{
  color: @colorTextGray;
}

// ---------------------------------------

.title
{
  display: flex;
  align-items: flex-start;
  flex: 1;
  min-height: 30px;
}

// ---------------------------------------

.staffMessage
{
  margin: 10px 20px;
}

// ---------------------------------------

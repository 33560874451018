@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  position: fixed;
  z-index: @zindexPageHeader + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;

  background: #fff;
}

// ---------------------------------------

.contentWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
}

// ---------------------------------------

.sidebar
{
  display: flex;
  flex-direction: column;
  flex: 0;
  min-width: 222px;

  border-right: @borderGray15;
}

.sidebarInner
{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 @fullscreenPageSidebarPadding @fullscreenPageSidebarPadding;
}

// ---------------------------------------

.title
{
  padding: 18px 0 5px;

  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  color: @colorText;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    font-size: 18px;
  }
}

// ---------------------------------------

.btnBackWrapper
{
  display: flex;
  min-height: 50px;
  //margin: 0 -@positionSidebarPadding;

  background: rgba(235, 241, 245, 0.6);
}

.btnBack
{
  justify-content: flex-start;
  width: 100%;
  padding-left: 15px;

  font-weight: 500;
}

.btnBackIcon
{
  margin-right: 10px;
}

// ---------------------------------------

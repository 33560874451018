@import "/src/styles/variables";

// ---------------------------------------

.priorityPill
{
  position: relative;
  z-index: 2;

  display: flex;
  align-items: center;
  flex-shrink: 1;
  min-width: 25px;
  min-height: 25px;
  padding: 0 2px 0 11px;

  user-select: none;
  background: linear-gradient(0deg, rgba(41, 101, 204, 0.2), rgba(41, 101, 204, 0.2)), #fff;
  border-radius: 20px;

  &.btnAdd
  {
    padding: 0;
    justify-content: center;

    background: #ebf1f5;

    &:hover
    {
      background: rgba(41, 101, 204, 0.15);
    }
  }

  &:hover
  {
    cursor: pointer;
    background: linear-gradient(0deg, @candidateFormHeaderBg, @candidateFormHeaderBg), #fff;

    .groupCount
    {
      background: rgba(235, 241, 245, 0.6);
    }
  }

  &.isEditMode
  {
    outline: 1px solid @colorLink;
  }

  &.isDisabled
  {
    cursor: default;
    color: @colorTextLightGray;
    background: linear-gradient(0deg, #e1e8ed, #e1e8ed), #fff;
  }

  &.isDuplicate
  {
    background: linear-gradient(0deg, rgba(255, 201, 64, 0.3), rgba(255, 201, 64, 0.3)), #fff;
    outline: 1px solid #f5903d;

    &:hover
    {
      background: linear-gradient(0deg, #f1dba3, #f1dba3), #fff;
    }

    .groupCount
    {
      background: rgba(41, 101, 204, 0.2);
    }
  }

  &.isDragging
  {
    .grayShadow;
  }
}

// ---------------------------------------

.btnAddIcon
{
  color: @colorTextGray;
  opacity: .6;
}

// ---------------------------------------

.positionGroupName
{
  flex-shrink: 1;
  min-width: 0;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon
{
  margin: 0 -3px 1px 7px;
  color: @colorTextGray;
  opacity: .6;
}

.groupCount
{
  height: 21px;
  width: 21px;
  flex-shrink: 0;
  margin-left: 10px;

  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: @colorLink;

  background: #ebf1f5;
  border-radius: 20px;
}

// ---------------------------------------

.priorityCellViewPopoverPlace
{
  display: flex !important; // popover 2
  flex-shrink: 1;
  min-width: 0;
}

.priorityCellViewPopoverWrapper
{
  //margin-top: -8px;
  max-height: 302px;
  overflow-y: auto;

  margin-top: 3px;
  margin-bottom: 3px;
}

// ---------------------------------------



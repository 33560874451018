@import "/src/styles/variables";

// ---------------------------------------

.wrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

// ---------------------------------------

.scrolled
{
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 15px;
}

// ---------------------------------------

.error
{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.errorText
{
  margin-left: 10px;
  color: #f00;
}

// ---------------------------------------

.isTeamError
{
  //position: relative;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px rgba(255,0,0,.5);
  //box-shadow: 0 0 0 1px rgba(255,0,0,.5);
  border-radius: 3px;
  margin-bottom: 5px;
  background: #fff;
}

.isTeamErrorIcon
{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  color: #f00;
  background: #fdd;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255,0,0,.1);
}

.customerAndTeam + .positionItem
{
  border-top: 0;
  margin-top: 0 !important;
}

.positionName
{
  font-weight: 700;
}

// ---------------------------------------

.positions
{
}

.positionWrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

// ---------------------------------------

.noPositions
{
  margin-top: 15px;
}

// ---------------------------------------

.autoComputed
{
  margin-top: 15px;

  &:first-child
  {
    margin-top: 0;
  }
}

.allocationsTitle
{
  font-size: 14px;
  color: @colorTextGray;
  padding-top: 4px;
  margin-bottom: 10px;
}

.allocations
{
}

.allocation
{
  margin-top: 10px;

  &:first-child
  {
    margin-top: 0;
  }
}

.allocationBorder
{
  margin-top: 10px;
  border: 1px solid @colorBorderGray;
  border-radius: 3px;

  &:first-child
  {
    //margin-top: 0;
  }

  &.isFinal
  {
    border-color: @colorSlotBlue;
  }

  &.isLeaving
  {
    border-color: @colorSlotOrange;
  }
}

// ---------------------------------------

.finalizedBy
{
  display: flex;
  align-items: center;
  margin-top: 10px;

  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;
}

.finalizedByAvatar
{
  margin-right: 10px;
}

.allocationNotes
{
  margin: 9px 0 10px 26px;
  color: @colorTextGray;
}

// ---------------------------------------

.slotWrapper
{
  position: relative;
  padding: 0 15px 12px;
  width: 100%;

  &:first-child
  {
    padding-top: 5px;
  }
}

// ---------------------------------------

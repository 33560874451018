@import '/src/styles/variables';

// ---------------------------------------

.selectStage
{
  .popoverWrapper();
}

// ---------------------------------------

.menuItemFlowStage
{
  padding: 6px 11px 5px;
  font-size: 16px;
  line-height: 29px;

  &.isSelected,
  &.isSelected:hover
  {
    //pointer-events: none;
    //color: @colorText;
    box-shadow: inset 0 0 0 1px @colorLink;
    //background: #fff;
  }

  &.isDisabled
  {
    //pointer-events: none;
    background-color: inherit !important;
    color: rgba(95, 107, 124, 0.6) !important;
    cursor: not-allowed !important;
    outline: none !important;
  }
}

.selectStage
{
  :global .bp4-active.bp4-disabled
  {
    box-shadow: inset 0 0 0 1px @colorGray20;
    //background: @colorGray05 !important;
  }
}

// ---------------------------------------

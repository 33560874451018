@import '/src/styles/variables';

// ---------------------------------------

.toolbarWrapper
{
  position: sticky;
  top: 0;
  z-index: 2;
  margin: -10px -10px 10px;
  padding: 10px;
  background: #fff;
}

.toolbar
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 42px;
  //margin-bottom: 10px;
  padding: 0 6px;

  border-radius: 2px;
  background: linear-gradient(0deg, #f5f8fa, #f5f8fa), #fff;

  .btn
  {
    flex-grow: 0;
    flex-shrink: 0;

    min-width: 0;
    margin-right: 10px;

    color: @colorTextGray;

    &.btnCallDisabled
    {
      pointer-events: none;
    }

    .btnIcon
    {
      color: currentColor;
    }

    &:hover
    {
      color: @colorTextGray;
      background: rgba(41, 101, 204, 0.1);
    }

    &.pressed
    {
      color: @colorTextLightGray;
      background: rgba(41, 101, 204, 0.1);
      transition: all .2s ease-out;
    }
  }

  .spacer
  {
    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;
  }
}

// ---------------------------------------

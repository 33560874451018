@import '/src/styles/variables';

// ---------------------------------------

.header
{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 50px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding-left: 36px;
    padding-right: 10px;
  }

  .headerTitle
  {
    color: @colorText;

    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}

// ---------------------------------------

.name
{
  width: 100%;
  margin-left: 15px;

  .desktop
  {
    display: none;
  }

  .mobile
  {
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-left: 0;
    width: @teamsListWidth_Name;

    .desktop
    {
      display: block;
    }

    .mobile
    {
      display: none;
    }
  }
}

.alias
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
    width: @teamsListWidth_Alias;
  }
}

.total
{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  margin-right: 33px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-right: 2px;
  }
}

// ---------------------------------------

.btnCollapseAll
{
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  display: none;
  margin-left: auto;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
  }
}

// ---------------------------------------


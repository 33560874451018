@import '/src/styles/variables';

// ---------------------------------------

.selectPipeline
{
  :global
  {
    .bp4-popover-open
    {
      background: @colorGray05;
      border-radius: 3px;
    }

    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-left: 1px !important;
      margin-top: 3px !important;
    }
  }
}

// ---------------------------------------

.btnSelectPipeline
{
  white-space: nowrap;

  font-size: 14px !important;
  color: @colorTextGray !important;

  .iconLeft
  {
    color: @colorTextLightGray;
  }

  .iconCaret
  {
    color: @colorTextGray;
    opacity: .5;
  }

  &.isDisabled
  {
    .iconLeft
    {
      color: @colorTextLightGray !important;
    }

    //color: @colorTextGray;
    //cursor: default;
    //background: unset !important;
  }
}

// ---------------------------------------



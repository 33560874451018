@import "/src/styles/variables";

@desktopSidebarWidth: 222px;

// ---------------------------------------

.wrapper
{
  position: relative;
  width: 0;
  z-index: @zindexSidebar; // BP <ButtonGroup> <Button> z-index = 4 !!! ???

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @desktopSidebarWidth;
  }
}

// ---------------------------------------

.sidebar
{
  position: absolute;
  z-index: 2;

  display: flex;
  flex-direction: column;
  //top: @headerHeight;
  top: 0;
  left: 0;
  bottom: 0;

  padding: 0 8px;
  overflow: auto;

  border-right: 1px solid #dbdcdd;

  transition: transform .1s ease-out; // hide
  transform: translateX(-100%);
  background: #fff;

  &.isMobileMenuVisible
  {
    transform: translateX(0);
    transition: transform .3s ease-out; // show
  }

  @media (max-width: @siteBreakPointDesktopMin)
  {
    width: 100vw;
    max-width: @desktopSidebarWidth;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @desktopSidebarWidth;
    transform: translateX(0);
  }
}

// ---------------------------------------

.mobileGlass
{
  position: fixed;
  z-index: 1;
  top: @appHeaderHeight;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);

  opacity: 0;
  transition: opacity .1s ease-in; // hide

  pointer-events: none;

  &.isMobileMenuVisible
  {
    opacity: 1;
    transition: opacity .3s ease-out; // show

    pointer-events: all;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none;
  }
}

// ---------------------------------------

.subMenu,
.menu
{
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}

.subMenu
{
  margin: 0;
}

.menuItem
{
  .menuItemLink
  {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 24px;
    margin-bottom: 1px;

    color: @colorText;
    text-decoration: none;

    border-radius: 0;

    &.level1
    {
      display: flex;
      align-items: center;
      margin-top: 17px;
      margin-bottom: 4px;
      // min-height: 30px;
      cursor: default;
      font-size: 12px;
      color: @colorBorderGray;

      @media (max-width: @siteBreakPointDesktopMin)
      {
        font-size: 14px;
        height: 30px;
      }
    }

    &.level2
    {
      font-size: 14px;

      &.isActive,
      &:hover
      {
        background: #ebf1f5 !important;
        z-index: 1;
      }

      @media (max-width: @siteBreakPointDesktopMin)
      {
        font-size: 18px;
        height: 50px;
      }
    }

    &:hover
    {
      // TODO: ?
      //box-shadow: 0 0 0 1px rgba(255, 255, 255, 1) inset;
      //border-top: 1px solid #fff;
      //border-bottom: 1px solid #fff;
    }

    &.isActive
    {
      //border: none;
    }

  }

  &:first-child
  {
    .level1
    {
      margin-top: 0;
    }
  }
}

// ---------------------------------------

.menuItemSettingsWrapper
{
  margin-top: auto;
}

.menuItemSettings
{
  display: flex;
  justify-content: flex-start;
  flex: 1;
  width: calc(100% + 16px);
  height: 65px;
  margin: 0 -8px;
  padding-left: 23px;

  color: @colorTextLightGray !important;

  border-top: 1px solid #e5e5e5;
  border-radius: 0;

  @media (max-width: @siteBreakPointDesktopMin)
  {
    padding-left: 32px;
    font-size: 18px;
  }

  .menuItemSettingsIcon
  {
    color: @colorTextLightGray;
  }

  &:hover
  {
    color: @colorTextGray !important;

    .menuItemSettingsIcon
    {
      color: @colorTextGray;
    }
  }
}

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 2px;
  padding-top: 18px;
}

.headerTitle
{
  padding: 0 10px 0 (32px - 8);

  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
  letter-spacing: 1px;
}

.tooltipWrapper
{
  margin-left: auto;
}

.btnBackWrapper
{
  display: block;
}

.btnBack
{
  margin: (24px - 30px) / 2 0;

  color: @colorLink !important;
  background: #ebf1f5 !important;

  &:hover
  {
    color: @colorTextGray;
    background: rgba(41, 101, 204, 0.2) !important;

    .btnBackIcon
    {
    }
  }

  .btnBackIcon
  {
    color: @colorLink;
  }
}

// ---------------------------------------

@import "/src/styles/variables";

.wrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;

  min-height: 60px; // min btn height = 30 + 15 top/bottom paddings

  @media (min-width: @siteBreakPointDesktopMin)
  {
    //flex-direction: row;
  }
}

// ---------------------------------------

.left,
.center,
.right
{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  min-width: 0;
}

.left
{
  @media (max-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 10px;
  }
  @media (min-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 0;
  }
}

.center
{
  justify-content: center;

  @media (max-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 10px;
  }
  @media (min-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 0;
    //justify-content: center;
  }
}

.right
{
  justify-content: flex-end;

  @media (max-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 10px;
  }
  @media (min-width: @siteBreakPointDesktopMin)
  {
    //margin-top: 0;
    //justify-content: flex-end;
  }
}

// ---------------------------------------

.icon
{
  margin-right: 14px;
  color: @colorIcons;
}

.title
{
  margin-right: 20px;

  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  color: @colorText;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    font-size: 18px;
  }
}

// ---------------------------------------

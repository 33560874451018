@import '/src/styles/variables';

// ---------------------------------------

.constraintsBtnWrapper
{
  .btnConstraints
  {
    margin-left: 10px;

    font-size: 24px;
    font-weight: 500;
    color: @colorOrange;

    &:hover
    {
      color: @colorOrange;
      border-color: @colorLink;
      background: rgba(41, 101, 204, 0.1);
    }
  }
}

// ---------------------------------------

@import '/src/styles/variables';

.card
{
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  //min-height: 106px;

  margin-top: 5px;
  padding: 10px 12px;

  cursor: pointer;

  border-radius: 3px;
  background: #fff;
  //box-shadow: 0 1px 1px @colorGray20, 0 1px 4px @colorGray10;

  user-select: none;

  &:hover
  {
    background: @colorHeaderHoverBg;
  }

  &:first-child
  {
    margin-top: 0;
  }

  .personInfo
  {
    display: flex;

    .avatar
    {
      margin-right: 8px;
    }

    .cardTitle
    {
      overflow: hidden;

      flex-grow: 1;
      flex-shrink: 1;

      min-width: 0;

      text-overflow: ellipsis;

      color: @colorTextDark;

      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .infoRow
  {
    display: flex;
    align-items: flex-start;

    flex-shrink: 1;

    min-width: 0;
    margin-top: 8px;

    &.isTeamError
    {
      margin-left: -5px;
      margin-right: -5px;
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px rgba(255, 0, 0, .5);
      border-radius: 3px;
      background: #fff;
    }

    .isTeamErrorIcon
    {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      color: #f00;
      background: #fdd;
      border-radius: 3px;
      box-shadow: inset 0 0 0 1px rgba(255, 0, 0, .1);
    }

    .infoIconWrapper
    {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 15px;
      min-width: 4px + 12px + 4px;
      margin-right: 12px - 4px;
    }

    .infoIcon
    {
      display: flex;
      justify-content: center;

      color: @colorTextLightGray;

      &.feedbackDoneIcon
      {
        color: @colorSlotBlue;
      }

      &.waitingFeedbackIcon
      {
        color: @colorSlotOrange;
      }

      &.scheduledCallIcon
      {
        color: @colorLink;
      }

      &.finalized
      {
        margin-bottom: 1px;
        color: @colorLink;
      }

      &.warning
      {
        color: #f00;
      }

      &.iconProposed
      {
        margin-bottom: 1px;
      }
    }

    .infoText
    {
      display: flex;
      flex-direction: column;

      flex: 1;
      min-width: 0;

      color: @colorText;

      font-size: 10px;
      font-weight: 400;
      line-height: 15px;

      .infoTextLine
      {
        text-overflow: ellipsis;

        &.gray
        {
          color: @colorTextLightLightGray;
        }

        &.relative
        {
          position: relative;
        }
      }

      .positionRow
      {
        display: flex;
        align-items: center;
        margin-top: 2px;
      }

      .infoSingleTextLine
      {
        overflow: hidden;

        flex-grow: 0;
        flex-shrink: 0;

        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 10px;
        font-weight: 400;
        line-height: 15px;

        .arrowIcon
        {
          height: 10px;
          margin: 0 5px;
          transform: translateY(.5px);
        }

        &.positionInfo
        {
          flex-grow: 1;
          flex-shrink: 1;

          min-width: 0;

          font-size: 10px;
          font-weight: 700;
          line-height: 15px;
        }
      }
    }
  }

  .cardPosition
  {
    overflow: hidden;

    margin-top: 2px;

    text-overflow: ellipsis;

    color: @colorTextLightGray;

    font-size: 12px;
    line-height: 15px;
  }

  .cardCustomers
  {
    overflow: hidden;

    margin-top: 8px;

    text-overflow: ellipsis;

    color: @colorTextDark;

    font-size: 10px;
    line-height: 15px;

    .cardIcon
    {
      margin-right: 5px;
      margin-bottom: 1px;

      color: @colorTextLightGray;
    }
  }

  .spacer
  {
    flex-grow: 1;
    flex-shrink: 1;

    min-height: 0;
  }

  .cardDate
  {
    align-self: flex-end;

    color: @colorTextLightLightGray;

    font-size: 10px;
    line-height: 15px;
  }

  .draggingOverlay
  {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border: dashed 1px @colorDropTargetBg;
    border-radius: 3px;
    background: @colorPillBg;
  }
}

// ---------------------------------------

.confirmationIcon
{
  position: absolute;
  left: -31px;
  top: -3px;

  &.blue
  {
    color: @colorLink;
  }

  &.red
  {
    color: #db3737;
  }

  &.gray
  {
    color: @colorTextLightGray;
  }
}

// ---------------------------------------

.assignedTo
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.assignedName
{
  min-width: 0;
  max-width: calc(100% - 28px - 17px); // left align by content
  margin-top: 1px;

  .textEllipsis();
  font-size: 10px;
  line-height: 13px;
}

.assignedToIcon
{
  margin-right: 7px;
  color: @colorTextLightGray;
}

// ---------------------------------------

//.positionGroups
//{
//  padding-left: 28px;
//  font-size: 10px;
//  line-height: 15px;
//
//  .textEllipsis();
//}

.positionGroups
{
  height: 15px;
  padding-left: 28px;
  font-size: 10px;
  line-height: 15px;
}

.positionGroupsPlusX
{
  color: rgba(24, 32, 38, 0.5);
}

// ---------------------------------------

.tooltipWrapper
{
  display: flex !important;

  margin: -2px 0 -3px 6px;
  padding: 4px 5px 4px 4px;
  height: 20px;

  border-radius: 10px;
  background: #ebf1f5;
}

.tooltipAvatar
{
  margin-right: 6px;
}

.tooltipInfo
{
  max-width: 400px;
  min-width: 150px;
  .textEllipsis();
  text-align: center;
}

.tooltipIcons
{
  display: flex;
  align-items: center;
}

.userName
{
  font-weight: bold;
}

.positionInfoIcon,
.warnIcon
{
  color: @colorSlotOrange;

  &.isBlue
  {
    color: @colorLink;
  }
}

.positionInfoIcon
{
  margin-bottom: 1px;
}

// ---------------------------------------

.debug
{
  font-size: 12px;
  font-weight: normal;
  color: #f00;
}

// ---------------------------------------

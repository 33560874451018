@import "/src/styles/variables";

// ---------------------------------------

.popover
{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
  margin-right: 5px;

  &:first-child
  {
    margin-right: 0;
  }
}

.btnFilter
{
  &.noValueSelected
  {
    white-space: nowrap;
    // background: rgba(92, 112, 128, 0.7);
    // opacity: 0.2;
    // !!! => #e0e4e8
    background: #e0e4e8 !important;

    &:hover
    {
      background: darken(#e0e4e8, 5) !important;
    }
  }
}

// ---------------------------------------

.wrapper
{
  min-width: 222px;
  max-width: 222px;
  background: #fff;
}

.content
{
  padding: 10px 15px;
  user-select: none;
}

.title
{
  margin-bottom: 10px;
  font-size: 14px;
  color: @colorText;
}

// ---------------------------------------

.btnClear
{
  width: 100%;
  min-height: 50px;
  font-size: 12px;
  color: @colorTextGray !important;
  border-top: 1px solid @colorBorder;

  &:hover
  {
    color: @colorText !important;
  }
}

// ---------------------------------------

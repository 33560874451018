@import "/src/styles/variables";

// ---------------------------------------

.priorityCellViewPopoverPlace
{
  display: flex !important; // popover 2
  flex-shrink: 1;
  min-width: 0;
}

.priorityCellViewPopoverWrapper
{
  margin-top: -2px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.toolbar
{
}

.positionLeft
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 30%;
  }

  @media (width: 320px)
  {
    min-width: 10%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 50%;
  }
}

.positionCenter
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 40%;
  }

  @media (width: 320px)
  {
    min-width: 40%;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
  }
}

.positionRight
{
  @media (max-width: @siteBreakPointMobileMax)
  {
    min-width: 30%;
  }

  @media (width: 320px)
  {
    min-width: 100px;
  }

  @media (min-width: @siteBreakPointMobileMax)
  {
    min-width: 50%;
  }
}

// ---------------------------------------

// .btnAdd
// {
//   display: none;

//   white-space: nowrap;
//   margin-right: 20px;

//   @media (min-width: @siteBreakPointDesktopMin)
//   {
//     display: block;
//   }
// }

// ---------------------------------------

// .positionsFilter
// {
//   width: 100%;
//   justify-content: center;

//   display: none;

//   @media (min-width: @siteBreakPointDesktopMin)
//   {
//     display: flex;
//   }
// }

// .buttonGroupBtn
// {
//   min-width: 115px;
// }

// ---------------------------------------

.toolbarTitle
{
  display: flex;
  align-items: center;
  margin-right: 20px;

  font-size: 15px;
  font-weight: 900;
  letter-spacing: .5px;
  color: @colorText;

  &.desktop
  {
    display: none;
  }

  @media (min-width: @breakPointAndroidCommon)
  {
    letter-spacing: 1px;
    font-size: 16px;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    font-size: 18px;

    &.desktop
    {
      display: block;
    }

    &.mobile
    {
      display: none;
    }
  }
}

// .toolbarTitleIcon
// {
//   margin-left: 5px;

//   @media (min-width: @breakPointAndroidCommon)
//   {
//     margin-left: 10px;
//   }

//   color: @colorTextGray;
// }

// ---------------------------------------

// .viewMobile
// {
//   @media (min-width: @siteBreakPointDesktopMin)
//   {
//     display: none !important; // popover2
//   }
// }

// ---------------------------------------

// .toolbarBtn
// {
//   display: none;
//   min-width: 105px;
//   margin-left: 10px;

//   &:last-child
//   {
//     //min-width: 100px;
//   }

//   white-space: nowrap;

//   &.mobileViewByUsers
//   {
//     display: flex;
//   }

//   @media (min-width: @breakPointAndroidCommon)
//   {
//     min-width: 115px;
//   }

//   @media (min-width: @siteBreakPointMobileMax)
//   {
//     display: flex;
//     min-width: 125px;
//   }
// }

// .mobileFilterBtn
// {
//   min-width: 45px !important;

//   &.isEditMode
//   {
//     display: none;
//   }

//   @media (width: 320px)
//   {
//     //min-width: 50px !important;
//   }

//   @media (min-width: @siteBreakPointMobileMax)
//   {
//     display: none;
//   }
// }

// ---------------------------------------

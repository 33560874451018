@import "/src/styles/variables";

// ---------------------------------------

.spinnerWrapper
{
  height: 30px;
  margin-bottom: 10px;
}

// ---------------------------------------

.filters
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
}

.btnFilter
{
  height: 30px;
  margin-bottom: 10px;

  &:last-child
  {
    margin-right: 0;
  }
}

.positionLeft
{
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-right: 5px;
}

.positionRight
{
  display: flex;
  margin-left: auto;
  align-items: flex-start;
}

// ---------------------------------------

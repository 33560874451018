@import '/src/styles/variables';

.groupWrapper
{
  padding-right: 10px;
  padding-left: 10px;

  border-radius: 3px;

  &.isGrouped
  {
    background: @colorPageBackground;
  }

  .stagesWrapper
  {
    display: flex;

    margin: 0 -5px;

    .cardsWrapper
    {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      margin: 0 5px;
    }
  }

  & + .groupWrapper
  {
    margin-top: 10px;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.select
{
  .popoverWrapper();
}

// ---------------------------------------

.dropZone
{
  width: 100%;
  height: 150px;
  background-color: #fff;
  padding: 4px;
  border-radius: 3px;
  color: @colorLink;
  cursor: pointer;

  &.isDraggingOver
  {
    background-color: @colorDropZoneBackground;
  }

  &:hover
  {
    color: @colorLinkHover;
  }

  .dropZoneBorder
  {
    width: 100%;
    height: 100%;

    border: 1px dashed fade(@colorTextLightGray, 50);

    .content
    {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &.isDraggingOver
      {
        opacity: 0.6;
      }

      .circle
      {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: @colorBgOrderButton;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .label
      {
        padding: 0 15px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  &.isSizeLimit
  {
    .dropZoneBorder
    {
      border: 1px solid fade(#db3737, 50);
    }
  }
}

// ---------------------------------------

.gray
{
  font-weight: 400;
  color: @colorTextGray;
}

.fileSize
{
  white-space: nowrap;
}

.error
{
  color: #f00;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-top: 8px;

  font-size: 12px;
  color: @colorTextGray;
}

.icon
{
  margin: 0 2px 1px;
  transform: translateY(-0.5px);
}

// ---------------------------------------

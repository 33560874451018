@import '/src/styles/variables';

// ---------------------------------------

.boardPopoverStyle()
{
  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-left: 1px !important;
      margin-top: 3px !important;
    }

    .bp4-menu
    {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}

.select
{
  .boardPopoverStyle();
}

// ---------------------------------------

.selectBtn
{
  max-width: 164px; // btn -> [All position groups]
  .buttonTextEllipsis();

  &.default
  {
    background: #E0E4E8 !important;
  }
}

.selectBtnCustomer
{
  min-width: 130px;
}

.menuItem
{
  max-width: 250px;
}

// ---------------------------------------

.selectFlow
{
  margin-right: 38px;

  .boardPopoverStyle();

  :global
  {
    .bp4-popover-open
    {
      background: @colorGray05;
      border-radius: 3px;
    }

    //.bp4-popover // = popoverProps.popoverClassName: ...
    //{
    //  max-height: 60vh;
    //  overflow-y: auto;
    //
    //  margin-left: 1px !important;
    //  margin-top: 3px !important;
    //}
  }
}

// ---------------------------------------

.selectFlowBtn
{
  color: @colorText;
  font-size: 18px;
  line-height: 20px;
  font-weight: 900;

  letter-spacing: 1px;

  //.buttonTextEllipsis();
}

// ---------------------------------------

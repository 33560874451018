@import '/src/styles/variables';

// ---------------------------------------

.select
{
  .popoverWrapper();

  :global
  {
    .bp4-menu
    {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.unassigned
{
  color: @colorTextGray !important;
}

// ---------------------------------------

.menuItem
{
  .menuItemStyle();
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.btnFilter
{
  height: 30px;
  margin-bottom: 10px;

  &:last-child
  {
    margin-right: 0;
  }
}

// ---------------------------------------

.filtersList
{
  min-width: 174px;
  max-width: 174px;

  .buttonTextEllipsis();

  &.isFiltersDirty
  {
    font-style: italic;
    border-color: #ec9b3d;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

// ---------------------------------------

.btnSave
{
  height: 30px;
  margin: 0 (20px - (30-16)/2);
}

// ---------------------------------------

.noFilters
{
  padding: 16px;
  text-align: center;
}

// ---------------------------------------

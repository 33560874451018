@import '/src/styles/variables';

// ---------------------------------------

.icon
{
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  vertical-align: top;

  &.size24
  {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}

// ---------------------------------------

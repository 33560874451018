@import "/src/styles/variables";

// ---------------------------------------

// @searchWrapperWidth: (1218px - 24 - 24);
// @searchWidth: 518px;
// @searchWidthPerc: (@searchWidth / @searchWrapperWidth) * 100%;
// @leftRightWidth: (100 - @searchWidthPerc) / 2;

.positionLeft
{
  // width: 0;

  @media (min-width: @siteBreakPointMobileMax)
  {
    width: 30%;
    // width: 50%;
    // width: @leftRightWidth;
  }
}

.positionCenter
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: 40%;
    // width: @searchWidthPerc;
  }
}

.positionRight
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: 30%;
    // width: @leftRightWidth;
  }
}

// ---------------------------------------

.btnAdd
{
  white-space: nowrap;
  margin-right: 20px;
  margin-left: 30px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    margin-left: 0px;
  }
}

.searchInput
{
  flex: 1;
  min-width: 240px;
}

// ---------------------------------------

.spinnerWrapper
{
  min-width: 95px * 3 - 2;
  height: 30px;

  &.mobile
  {
    width: 100%;

    @media (min-width: @siteBreakPointDesktopMin)
    {
      display: none;
    }
  }

  &.desktop
  {
    @media (max-width: @siteBreakPointDesktopMin)
    {
      display: none;
    }
  }
}

// ---------------------------------------

.statusSwitcher
{
  width: 100%;
  display: flex;
  justify-content: center;

  .statusSwitcherBtn
  {
    min-width: 95px;

    @media (max-width: @breakPointIPhonePlus)
    {
      min-width: 33.5% !important;
    }
  }

  &.mobile
  {
    @media (min-width: @siteBreakPointDesktopMin)
    {
      display: none;
    }
  }

  &.desktop
  {
    @media (max-width: @siteBreakPointDesktopMin)
    {
      display: none;
    }
  }
}

.statusSwitcherGroupBtn
{
  @media (max-width: @breakPointIPhonePlus)
  {
    min-width: 100% !important;
  }
}


.secondRow
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 10px 5px;

  max-width: calc(100vw - 20px);

  justify-content: center;

  margin-bottom: 10px;
}

@import "/src/styles/variables";
@import "PrioritiesListByCommon.module";

// ---------------------------------------

.priorityCellView
{
  padding-left: 0;
}

// ---------------------------------------

.user
{
  min-width: 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.groupName
{
  flex: 1;

  font-size: 14px;
  color: @colorText;
}

.groupCount
{
  min-width: 30px;
  padding-left: 10px;

  color: @colorLink;
  font-size: 16px;
  text-align: right;
}

// ---------------------------------------

.priorityCellPositions
{
  display: none;

  &.isExpanded
  {
    display: block;
  }
}

// ---------------------------------------


@import '/src/styles/variables';

// ---------------------------------------

@positionSidebarPadding: 15px;

// ---------------------------------------

.link
{
  align-self: flex-start;
  color: @colorTextGray;

  .icon
  {
    vertical-align: top;
    margin-right: 7px;

    &.drive
    {
      margin-top: 1px;
    }
  }

  &:hover
  {
    text-decoration: none;
    color: @colorLink;
  }

  + .link
  {
    margin-top: 15px;
  }
}

// ---------------------------------------

.divider
{
  margin: 20px -@positionSidebarPadding;
  height: 1px;
  background: #E5E5E5;
}

// ---------------------------------------

.btnEditDescription
{
  margin-bottom: 15px;
}

// ---------------------------------------

.lastModified
{
  margin: -5px 0 20px;

  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;
}

// ---------------------------------------

.readyWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 40px;
  padding: 10px 15px;

  background: #EBF1F5;
  border-radius: 3px;
}

.switchReady
{
  margin: 0;
}

// ---------------------------------------

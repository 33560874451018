@import '/src/styles/variables';

@widthInputClosed: 85px;
@widthInputOpened: 320px;

// ---------------------------------------

.newPositionDialog
{
  width: @dialogWidth_Large;

  @media (max-width: @siteBreakPointMobileMax)
  {
    .positionDialogContentWrapper
    {
      //max-height: 60%;
    }
  }
}

// ---------------------------------------

.searchInputWrapper
{
  position: relative;
}

.positionSearchInput
{
  position: absolute;
  display: flex;
  min-width: 0;
  top: -70px;
  right: 0;

  &:hover
  {
    :global
    {
      .bp4-icon
      {
        color: @colorLink;
      }
    }
  }

  :global
  {
    .bp4-icon
    {
      color: @colorTextGray06;
      pointer-events: none;
    }

    .bp4-input::placeholder
    {
      color: @colorTextGray06;
    }

    .bp4-input
    {
      box-shadow: none !important;
      background: none;
      width: 40px !important;
      flex-grow: 1;
      flex-shrink: 0;
      cursor: pointer;
      margin-left: -20px;
    }
  }

  &.inputIsOpened
  {
    width: @widthInputOpened !important;
    max-width: 100%;
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;

    @media (max-width: 500px)
    {
      width: 100% !important;
    }

    :global
    {
      .bp4-icon
      {
        color: @colorTextGray;
      }

      .bp4-input
      {
        margin-left: 0;
        position: relative;
        transition: width .1s ease-out !important;
        box-shadow: 0 1px 0 0 @colorGray20 !important;
        border-radius: 0 !important;
      }
    }

    &:hover
    {
      :global .bp4-icon
      {
        color: @colorTextGray;
      }
    }
  }
}

// ---------------------------------------

.staffRoleName
{
  font-weight: 400;
  line-height: 18px;
  margin: 18px 0 14px 0 !important;
  color: @colorTextGray;
}

.positionDialogContentWrapper
{
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: -20px;
}

// ---------------------------------------

@import "/src/styles/variables";

.btn
{
  &.btnFlow
  {
    // overwrite BP button styles here
  }
}

.btnWrapper
{
  user-select: none;
  text-decoration: none;

  &:hover
  {
    text-decoration: none;
  }

  &.disabled
  {
    pointer-events: none;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.radioGroup
{
  padding: 11px 0;
}

.textArea
{
  min-height: 90px;
  resize: vertical;
}

// ---------------------------------------

@import "/src/styles/variables";

.profileButton
{
  span
  {
    display: flex;
  }

  .userAvatar
  {
    //width: 32px;
    //height: 32px;
    //border: 1px white;
    //border-radius: 50%;
    //overflow: hidden;
    //background: #e1e8ed;

    //.icon
    //{
    //  opacity: 0.15;
    //}

    cursor: pointer;

    &:hover
    {
      outline: 3px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.popup
{
  min-width: 320px;

  .profile
  {
    display: flex;
    position: relative;
    // margin-right: 24px; // 40
    // user-select: none;
    padding: 15px 20px;

    .userAvatarBig
    {
      margin-top: 5px;
      margin-right: 20px;
    }

    .userInfo
    {
      display: flex;
      flex-direction: column;

      .userName
      {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 2px;
      }
      .userRole
      {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;

        font-size: 12px;
        color: rgba(92, 112, 128, 1);
      }
      .userEmail
      {
        font-size: 12px;
        line-height: 15px;
        color: rgba(92, 112, 128, 0.6);
      }
    }
  }

  .actions
  {
    border-top: 1px solid #E5E5E5;
    padding: 7px 15px;

    color: rgba(92, 112, 128, 0.6);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .account
    {
      display: flex;

      .button
      {
        color: rgba(92, 112, 128, 0.6);
        margin-right: 10px;
        padding: 0 5px;

        .icon
        {
          color: rgba(92, 112, 128, 0.6);
        }
      }
    }

    .logout
    {
      .icon
      {
        color: rgba(92, 112, 128, 0.6);
      }
    }
  }
}

.profileButton
{
  > span > span {
    display: flex;
  }
}

.profilePortal
{
  z-index: @zindexPageHeader + 1;
}

.profilePopover
{
  @media (max-width: @breakPointIPhonePlus)
  {
    min-width: calc(100vw - 40px);
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.newCustomerDialog
{
  display: flex;
  flex-direction: column;

  width: @dialogWidth_Default;
}

// ---------------------------------------

.radio
{
  margin-top: 11px;

  &:last-child
  {
    margin-bottom: 11px !important;
  }
}

// ---------------------------------------

@import "/src/styles/variables";

.wrapper
{
  position: relative;
  z-index: @zindexPageHeader;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: @appHeaderHeight;

  background: #fff;
  .grayShadow;
}

.logo
{
  display: flex;
  width: 172px;
  height: 37px;
  max-width: 50%;

  @media (min-width: @siteBreakPointTabletMax)
  {
    margin-left: 32px;
  }

  @media (max-width: @siteBreakPointMobileMax)
  {
    width: 140px;
  }
}

.logoImage
{
  flex-shrink: 1;
  min-width: 0;
}

.btnMobileMenu
{
  color: @colorIcons !important;
  //border-color: #fff !important;

  min-width: @appHeaderHeight;
  min-height: @appHeaderHeight;
  //margin-right: (@headerHeight - 50px) / 2;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: none !important;
  }
}

.profileWrapper
{
  display: flex;
  align-items: center;

  margin-left: auto;

  text-shadow: none;

  padding: 3px 0;

  margin: 0 20px 0 0;
}

// .chatIcon
// {
//   margin-right: 15px;
//   color: @colorIcons;
//   cursor: pointer;
// }

// .notificationsIcon
// {
//   margin-right: 15px;
//   color: @colorIcons;
//   cursor: pointer;
// }

// .settingsIcon
// {
//   margin-right: 20px;
//   color: @colorIcons;
//   cursor: pointer;
// }

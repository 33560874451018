@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  background: #fff;
  min-height: 0;
  width: 100%;
}

.panelHeader
{
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 41px; // 40 + border
  padding: 0 20px;

  border-bottom: @borderGray15;
}

.panelHeaderTitle
{
  font-size: 14px;
  color: @colorTextGray;
}

.panelHeaderContent
{
  flex: 1;
}

// ---------------------------------------

.panelContent
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  width: 100%;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.select
{
  //min-width: 0;
  //.popoverWrapper();

  :global
  {
    .bp4-popover-open
    {
      background: @colorGray05;
      border-radius: 3px;
    }

    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      //max-height: 60vh;
      //overflow-y: auto;

      margin-left: 1px !important;
      margin-top: 3px !important;
    }

    .bp4-menu
    {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

// ---------------------------------------

.selectBtn
{
  max-width: 100%;

  color: @colorText;
  font-size: 18px;
  line-height: 20px;
  font-weight: 900;

  letter-spacing: 1px;

  .buttonTextEllipsis();
}

// ---------------------------------------

//.selectMenu
//{
//  max-height: 300px;
//  overflow-y: auto;
//}

.menuItem
{
  .menuItemStyle();
}

// ---------------------------------------


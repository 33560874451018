@keyframes icon-rotation-animation
{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner
{
  .spinnerIcon
  {
    animation: icon-rotation-animation 1000ms linear infinite;
  }
}

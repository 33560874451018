@import "/src/styles/variables";

// ---------------------------------------

.filterSiteContent
{

}

.filterSiteRow
{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.filterSiteTitle
{
  min-width: 54px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
}

.filterSiteBtn
{
  width: 100%;
}

// ---------------------------------------

.menuItem
{
  .menuItemStyle();

  &.isGroupHeader
  {
    font-weight: 500;
  }
}

// ---------------------------------------

.selectPopoverWrapper
{
  .popoverWrapper();

  :global
  {
    .bp4-menu
    {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

// ---------------------------------------

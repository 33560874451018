@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  display: flex;
}

// ---------------------------------------

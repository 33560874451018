@import "/src/styles/variables";

// ---------------------------------------

.field
{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;

  min-height: 30px;
  margin: 2px 0;
  padding-left: 10px;

  width: 100%;

  &.isViewMode
  {
    cursor: pointer;

    &:hover,:focus-within
    {
      background: #ebf1f5;
      border-radius: 3px;

      .btnEdit
      {
        opacity: 1;
      }

      .selectRightIcon
      {
        opacity: 1;
      }
    }
  }

  &.isReadOnly
  {
    cursor: unset;

    &:hover
    {
      background: unset;
    }
  }

  &.noButtons
  {
    padding-right: 10px;
  }

  &.isEditWithIcon
  {
    margin-left: -7px;
  }
}

.fieldIcon
{
  margin-right: 9px;

  color: @colorIcons;

  &.alignTop
  {
    margin-top: 7px;
    margin-bottom: auto;
  }
}

// ---------------------------------------

.view
{
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  color: @colorTextGray;
}

.text
{
  flex: 1;
  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 5px 0;
  line-height: 20px;

  &.textArea
  {
    margin: 6px 0;
    line-height: 18px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &.wrap
  {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

&.isChanged
{
  span,div
  {
    position: relative;
    text-decoration: underline;
    text-decoration-color: @colorInputValidation;
    text-underline-offset: 4px;
  }
}

.additionalFieldLabel{
  font-size: 12px;
  color: @colorTextLightGray;
}

.labelDot{
  padding: 0 7px;
}

.emptyText
{
  color: rgba(92, 112, 128, 0.5);
}

.selectRightIcon
{
  opacity: 0;
  margin: 0 11px 0 7px;
}

.btnEdit
{
  opacity: 0;
  margin-left: 10px;
  background: #ebf1f5 !important;

  &.alignTop
  {
    margin-bottom: auto;
  }

  &:focus
  {
    opacity: 1;
  }
}

.link
{
  color: inherit;
  line-height: 20px;
  display: inline-block;
}

.btnEditIcon
{
  color: #8a9ba8 !important;
}

// ---------------------------------------

.edit
{
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.inputs
{
  display: flex;
  flex-direction: column;
}

.inputWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;

  & + .inputWrapper
  {
    margin-top: 4px;
  }
}

.editButtons
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4px;
}

.inputLabel
{
  min-width: 100px;
  font-size: 14px;
  color: @colorTextGray;
  margin-right: 15px;
}

.input
{
  flex: 1;

  :global .bp4-input
  {
    box-shadow: 0 0 0 1px @colorGray20 inset;
  }
}

.btnSelect
{
  flex: 1;
  width: 100%;
  white-space: nowrap;
  background: #fff !important;

  &.isPlaceholder
  {
    color: rgba(92, 112, 128, 0.5) !important;
  }

  .buttonTextEllipsis();
}

// ---------------------------------------

.btnCancel,
.btnApply
{
  font-size: 12px;
}

.btnCancel
{
  color: @colorTextLightGray !important;
}

.btnApply
{
  //margin-right: -10px;
  color: @colorLink !important;
}

// ---------------------------------------

.menuItem
{

  &.isGroupHeader
  {
    font-weight: 500;
  }

  &.isMenuDivider
  {
    position: relative;
    margin-bottom: 11px;

    &:after
    {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -6px;
      height: 1px;
      background: @colorMenuDivider;
    }
  }
}

// ---------------------------------------

.selectPopoverWrapper
{
  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      margin-left: 1px !important;
      margin-top: 3px !important;
    }
  }
}

.selectPopoverTarget
{
  width: 100%;
}

.selectMenu
{
  max-width: 264px;
  max-height: 300px;
  overflow-y: auto;
}

// ---------------------------------------

.multiSelectView
{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  &.withTextBefore
  {
    .multiSelectViewText
    {
      padding: 6px 0;
    }
  }
}

.multiSelectViewText
{
  .textBefore
  {
    padding-top: 0;
  }
}

.textBefore
{
  padding-top: 6px;

  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  user-select: none;
}

.miltiSelectViewItem
{
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;

  &.isPills
  {
    height: 25px;
    padding: 0 12px 1px;
    margin: 3px 5px 2px 0;

    background: linear-gradient(0deg, rgba(19, 124, 189, 0.2), rgba(19, 124, 189, 0.2)), #fff;
    border-radius: 20px;
  }
}

.multiSelectEditWrapper
{
  display: flex;
  flex-direction: row;
}

.multiSelectEdit
{
  :global .bp4-input
  {
    box-shadow: 0 0 0 1px @colorGray20 inset;
  }
}

.multiSelectEditPopover
{

  :global
  {
    .bp4-popover // = popoverProps.popoverClassName: ...
    {
      //margin-left: 1px !important;
      //margin-top: 3px !important;
    }

    .bp4-menu
    {
      max-height: 40vh;
      overflow-y: auto;
    }
  }

  // !!! usePortal: true
  &.selectPopover
  {
    margin-left: 1px !important;
    margin-top: 3px !important;
  }
}

// ---------------------------------------

.tag
{
  &:first-child,&:nth-child(2)
  {
    svg
    {
      display: none;
    }
  }
}

@import "/src/styles/variables";

// ---------------------------------------

.filterTimeContent
{
}

.radioRangeWrapper
{
  display: flex;
}

.radioRangeRadio
{
}

.radioRangeInputs
{
  margin-top: -6px !important;
  margin-bottom: 5px !important;

  .row
  {
    display: flex;
    align-items: center;
  }

  .rowLabel
  {
    min-width: 50px;
    padding-right: 10px;
    //text-align: right;
  }

  .dateInput
  {
    margin-top: 0 !important;

    :global .bp4-input-action
    {
      pointer-events: none;
    }
  }
}

// ---------------------------------------

.btnWithIcon
{
  //pointer-events: none;
}

.btnIcon
{
  color: @colorIcons !important;
}

//.icon
//{
//  color: @colorIcons !important;
//  margin-top: 6px;
//  margin-right: 7px;
//  vertical-align: top;
//  pointer-events: none;
//}

// ---------------------------------------


@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  flex: 1;
  display: flex;
  flex-direction: column;
  //margin-top: 10px;
}

.buttons
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: -10px;
}

.btnAllocation
{
  margin-right: 10px;

  color: @colorLink !important;

  &.isDisabled
  {
    opacity: .5;
  }
}

// ---------------------------------------

.archivedMessage
{
  min-height: 120px;
  margin: 10px 20px;
}

.viewHistoryBtn
{
  margin: 3px 0 -8px;
  font-size: 12px;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.dateInputWrapper
{
}

.dateInput
{
  .dialogTextInput();

  :global .bp4-input
  {
    cursor: pointer;
  }

  &.isDateInputOpened
  {
    :global .bp4-input
    {
      .dialogInputFocusStyle();
    }
  }
}

// ---------------------------------------

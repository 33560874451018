@import "/src/styles/variables";

// ---------------------------------------

.candidatesWrapper
{
  flex: 1;
  display: flex;
  flex-direction: column;

  min-height: 36px;
  margin: 2px 5px 8px;

  background: #ebf1f5;
  border: 1px solid #8a9ba8;
  border-radius: 3px;

  &.fullWidth
  {
    @media (min-width: @siteBreakPointMobileMax) // 768
    {
      align-self: flex-start;

      min-width: calc((100% - 30px) / 4 - 10px);
      max-width: calc((100% - 30px) / 4 - 10px);
    }

    @media (min-width: @breakPointMacBookProDefault) // 1440
    {
      min-width: calc((100% - 50px) / 6 - 10px);
      max-width: calc((100% - 50px) / 6 - 10px);
    }
  }
}

// ---------------------------------------

.proposedCandidates
{
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  border-top: @borderGray15;
}

.proposedHeader
{
  padding: 16px 10px 4px;
  font-size: 12px;
  color: @colorTextGray;
}

.blankState
{
  min-height: 75px;
  margin: 9px 9px 4px;
  background: linear-gradient(0deg, #f5f8fa, #f5f8fa), #fff;
}

// ---------------------------------------

.candidate
{
  display: flex;
  flex-direction: row;
  align-items: center;

  //min-height: 36px;
  margin: (36px - 20) / 2 0;

  color: @colorText;

  &:hover
  {
    text-decoration: none;
    color: @colorLink;
  }
}

.avatar
{
  margin-right: 10px;
  margin-left: 9px;
}

.candidateName
{
  position: relative;
  padding-left: 1px;
  padding-right: 10px;

  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

// ---------------------------------------

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 34px;
  padding: 0 11px 0 11px;

  cursor: pointer;
  user-select: none;
}

.iconOpen
{
  margin-right: 15px;
  color: #8a9ba8;
  transform: translateY(-0.5px);
}

.headerText
{
  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;
}

.iconPerson
{
  margin-left: auto;
  margin-right: 6px;
  color: #8a9ba8;
  transform: translateY(-.5px);
}

.slotsCount
{
  font-size: 14px;
  color: @colorTextGray;
}

.arrowIcon
{
  margin: 0 5px;
  //color: @colorTextGray;
  color: @colorTextLightGray;
  transform: translateY(-.5px);
}

// ---------------------------------------

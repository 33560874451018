@import '/src/styles/variables';

// ---------------------------------------

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

// ---------------------------------------

.headerWrapper
{
  border-radius: 3px 3px 0 0;
  background: @colorHeaderBg;
}

.header
{
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  min-height: 50px;
  padding: 0 15px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding: 0 25px;
  }
}

.headerTitle
{
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: @colorText;

  &:last-child
  {
    padding-right: 0;
  }
}

.titleTitle
{
  flex: 1;
  min-width: 0;
}

.titlePositionTemplate
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: @positionsListWidth_PositionTemplate;
  }
}

.titleStaffRole
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
    width: @positionsListWidth_StaffRole;
  }
}

.titleStaffedTotal
{
  display: none;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    display: block;
    width: @positionsListWidth_StaffedTotal;
  }
}

.titlePositionGroup
{
  margin-left: auto;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.constraintsWarningsWrapper
{
  display: flex;
  min-height: 40px;
  margin-bottom: 10px;

  border: 1px solid @colorLink;
  border-radius: 3px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.panel
{
  display: flex;
  flex: 1;

  width: 100%;
  padding: 10px;
}


// ---------------------------------------

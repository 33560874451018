@import '/src/styles/variables';

// ---------------------------------------

.loader
{
  flex: 1;
}

.incrementalLoader
{
  height: 24px;
}

.content
{
  position: relative;
  //height: 100%;

  padding: 5px 8px;
}

.infiniteScroll
{
  overflow: visible !important;
}

// ---------------------------------------

//.userPill
//{
//  margin-bottom: 6px;
//  margin-left: -15px;
//}

.positionList
{
  margin: 6px 0;
  padding: 0 0 0 16px;
  width: 100%;

  .positionListItem
  {
    &.strikethrough
    {
      text-decoration: line-through;
    }
  }
}

// ---------------------------------------

.note
{
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  padding: 1px 0;

  color: @colorTextGray;

  font-size: 14px;
  line-height: 18px;
  //background: #ff0;
}

.noteRow
{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;

  //width: 100%;
  //word-break: break-all;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //background: #eef;

  &:first-child
  {
    margin-top: 0;
  }
}

.noteFieldName
{
  color: @colorText;
  font-weight: 500;
  padding-right: 3px;
  //background: #fcc;
}

.noteContent
{
  color: @colorTextGray;
}

.fromTo
{
  display: flex;
  //background: #cfc;

  color: @colorText;
  font-weight: 500;
}

.rightArrow
{
  margin: 4px 10px;
  // color: @colorText;
}

.userPillIcon
{
  vertical-align: text-top;
  margin: 0 6px 0 4px;
  color: @colorTextLightGray;
}

// ---------------------------------------

.notSet
{
  white-space: nowrap;
  font-weight: normal;
  color: @colorTextGray;
}

.rawContent
{
  white-space: pre-wrap;
  word-break: break-word;
}

.noteType
{
  align-self: flex-start;
  padding: 0 10px;
  margin-top: 10px;
  line-height: 24px;

  background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
  border-radius: 3px;
}

.titleDot
{
  margin: 0 8px 0 9px;
}

// ---------------------------------------

.contentItem
{
  position: relative;
  padding: 3px 0 10px 18px;
  border-left: 1px solid #c4c4c4;

  &.noBorder
  {
    border-left-color: transparent;
  }

  &.isDebug
  {
    background: #fff8f8;
    box-shadow: inset 0 0 0 1px rgba(255, 0, 0, .3);
  }

  &:last-child
  {
    border-left-color: transparent;

    .footer
    {
      border-bottom-color: transparent;
    }
  }

  &:before
  {
    content: '';
    position: absolute;
    top: 9px;
    left: -5px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: @colorTextLightGray;
    box-shadow: 0 0 0 9px #fff;
    transform: translate(.5px, 0);
  }

  &:hover
  {
    .pinIconWrapper
    {
      display: block;
    }
  }
}

// ---------------------------------------

.pinIconWrapper
{
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(191, 204, 214, 0.4);

  &:hover
  {
    background: rgba(191, 204, 214, 0.6);
  }

  &.isPinned
  {
    display: block;
    background: #ffc940;
  }

  .pinIcon
  {
    padding: 3px 4px;
    color: @colorText;
    transform: translate(.5px, .5px);
  }
}

// ---------------------------------------

.title
{
  margin-bottom: 10px;
}

.textBegin
{
  font-weight: 500;
}

.textEnd
{
  color: @colorTextGray;
}

.footer
{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-bottom: 14px;

  color: @colorTextGray;

  border-bottom: 1px solid rgba(16, 22, 26, 0.05);

  &.isTimeline
  {
    justify-content: flex-start;
  }
}

.userName
{
  margin-left: 20px;
}

// ---------------------------------------

.scheduledCallContent
{
  margin-top: -8px;
  margin-left: -10px;
  padding: 0 !important;
}
// ---------------------------------------

.feedbackLinkWrapper
{
  margin-top: 6px;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.positionsList
{
  //width: 240px;
  width: 280px;
  padding: 15px;

  &.mobileByUsers,
  &.mobileByPriorities
  {
    width: 100%;
    padding-right: 40px;

    border-bottom: @borderGray15;
    background: #fff;

    .divider
    {
      margin-right: -40px;
    }
  }
}

.empty
{
  color: @colorTextGray;
}

// ---------------------------------------

.positionWrapper
{
  display: flex;
  flex-direction: row;
  //align-items: start;

  margin-bottom: 12px;

  &:last-child
  {
    margin-bottom: 0;
  }

  &.error
  {
    background: #fee;
  }
}

.names
{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.customerName
{
  display: block;
  min-height: 15px;
  line-height: 15px;
  margin-top: 0;
  //background: #fcc;
}

.positionName
{
  //background: #ccf;
}

.slots
{
  align-self: flex-end;
  margin-left: 10px;

  //font-size: 16px;
  //line-height: 16px;
  //color: @colorLink;
}

.oldCount
{
  text-align: right;

  &.error
  {
    color: #f00;
  }
}

// ---------------------------------------

.title
{
  font-weight: 500;
  margin-bottom: 10px;
}

.divider
{
  margin: 12px -15px 8px + 4;
  //margin-bottom: 8px;
  border-top: @borderGray15;
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.listWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

// ---------------------------------------

.row
{
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 11px 15px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding: 11px 10px 11px 25px;
  }
}

.col1
{
  order: 1;
  width: 50%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    width: 25%;
  }
}

.col2
{
  order: 4;
  width: 50%;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 2;
    width: 25%;
  }
}

.col3
{
  order: 2;
  width: calc(50% - 30px);

  &.headerTitle
  {
    width: 50%;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 3;
    width: 20%;

    &.headerTitle
    {
      width: 20%;
    }
  }
}

.col4
{
  order: 5;
  width: calc(50% - 30px);

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 4;
    width: 20%;
  }
}

.col5
{
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 30px;
  margin-left: auto;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 5;
    min-width: 100px;
  }
}

// ---------------------------------------

.secondLineMobile
{
  margin-top: 8px;
  margin-bottom: 15px;
  padding-top: 2px;

  font-size: 12px;
  line-height: 16px;
  color: @colorTextGray;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;

    font-size: 14px;
    line-height: 18px;
    color: @colorText;
  }
}

.pgName
{
  font-weight: 500;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    max-width: none;
  }
}

.pgFlow
{
  padding-right: 10px;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.pgOpen
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.pgUsers
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.pgMenu
{
  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

// ---------------------------------------

.header
{
  min-height: 50px;

  border-radius: 3px 3px 0 0;
  //border-bottom: @borderGray15;
  background: @colorHeaderBg;
}

.headerTitle
{
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: @colorText;

  &:last-child
  {
    padding-right: 0;
  }

  &.desktop
  {
    display: none;
  }

  &.mobile
  {
    display: block;
    min-width: 50%;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    &.desktop
    {
      display: block;
    }

    &.mobile
    {
      display: none;
    }
  }
}

// ---------------------------------------

.groupsWrapper
{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.positionGroup
{
  //flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;
  min-height: 41px;

  background: linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #fff;
  border-bottom: @borderGray15;

  &:last-child
  {
    min-height: 40px;
    border-bottom: none;
  }

  padding-bottom: 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-direction: row;
    align-items: center;

    padding-bottom: 11px;
  }
}

// ---------------------------------------

.btnPositionGroupMenu
{
  margin: -6px 0;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

// ---------------------------------------

@openSlotCountSize: 24px;

.openSlotsCount
{
  flex-shrink: 0;
  height: @openSlotCountSize;
  width: @openSlotCountSize;
  margin: (18px - @openSlotCountSize)/2 0;

  text-align: center;
  font-size: 14px;
  line-height: @openSlotCountSize;
  font-weight: 500;
  color: @colorLink;

  background: #ebf1f5;
  border-radius: 20px;

  &.isZero
  {
    color: #a7b6c2;
    background: #e1e8ed;
  }
}

// ---------------------------------------

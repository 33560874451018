@import "/src/styles/variables";

.page
{
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;

  align-items: center;
  justify-content: center;

  background: @colorPageBackground;

  overflow: hidden;

  .loginCard
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform: translateY(-30px);

    .logoWrapper
    {
      margin-bottom: 20px;

      @media(min-width: @siteBreakPointMobileMax)
      {
        margin-bottom: 30px;
      }

      @media (max-height: @breakPointIPhonePlus)
      {
        margin-bottom: 0;
      }

      .logoImage
      {
        width: 280px;
        height: 60px;
      }
    }

    .pictureWrapper
    {
      margin-bottom: 20px;

      display: flex;
      align-items: flex-end;

      @media (max-height: @breakPointIPhonePlus)
      {
        margin-bottom: 0;
      }

      .picture
      {
        width: 272px;
        height: 160px;

        @media (max-height: @breakPointIPhonePlus)
        {
          transform: scale(0.8);
          height: 120px;
        }
      }
    }
  }
}

.form
{
  position: relative;

  width: 320px;
  height: 108px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: white;

  overflow: hidden;

  @media(min-width: @siteBreakPointMobileMax)
  {
    width: 383px;
    height: 130px;
  }

  @media (max-height: @breakPointIPhonePlus)
  {
    width: 300px;
    height: 80px;
  }

  .loadingButton
  {
    position: relative;
    display: flex;

    .spinner
    {
      color: #fff;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      margin-left: 18px;
    }
  }
}

.googleButtonWrapper
{
  width: 191px;
  height: 46px;

  display: flex;

  align-items: center;
  justify-content: center;
}

.googleButton
{
  cursor: pointer;
}

.errors
{
  top: calc(50% + 108px);
  position: absolute;
  text-align: center;
  color: red;
  left: 0;
  right: 0;
  padding: 0px 32px 16px;

  @media(min-width: @siteBreakPointMobileMax)
  {
    bottom: 0;
    top: unset;
  }
}

.version {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  color: @colorTextGray06;
}

.wavesBg
{
  position: absolute;
  z-index: -1;

  bottom: 0;
  width: 100%;

  height: 212px;

  background-position: center;
  background-size: cover;
  background-size: 100% 100%;

  @media (max-height: @breakPointIPhonePlus)
  {
    height: 123px;
  }
}

@import "/src/styles/variables";

// ---------------------------------------

.tabs
{
  flex: 1;
  display: flex;
  flex-direction: column;

  min-height: 40px;

  &.compact
  {
    flex-grow: 0;
    min-height: auto;
  }

  :global .bp4-tab
  {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 35px;
    max-width: 100% / 3;

    font-size: 14px;
    color: @colorTextGray;

    background: rgba(191, 204, 214, 0.15);
    border-radius: 3px 3px 0 0;

    transition: min-height .2s ease-out;

    // override some bp4 props
    margin-top: 0 !important;
    margin-right: 0 !important;
    box-shadow: unset !important;

    & + .bp4-tab
    {
      margin-left: 1px;
    }

    &:hover
    {
      min-height: 40px;
      cursor: pointer;
      color: @colorTextGray;
    }

    // deprecated -> use .activeTab
    //&.active
    //{
    //  min-height: 40px;
    //  background: #fff;
    //  z-index: 1;
    //
    //  &:hover
    //  {
    //    cursor: default;
    //  }
    //}
  }

  .activeTab
  {
    min-height: 40px;
    background: #fff;
    z-index: 1;

    &:hover
    {
      cursor: default;
    }

    .titleBadge
    {
      background: #f5f8fa;
    }
  }

  .disabled
  {
    opacity: .5;
    transition: none;

    &:hover
    {
      min-height: 35px;
      cursor: not-allowed;
    }
  }
}

// ---------------------------------------

.tabTitle
{

}

.titleBadge
{
  display: inline-block;
  min-width: 16px;
  height: 16px;
  margin-left: 5px;

  color: @colorTextLightGray;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;

  background: #fff;
  border-radius: 8px;
}

// ---------------------------------------

.tabPanel
{
  flex: 1;
  display: flex;
  min-height: 0;

  // override some bp4 props
  margin-top: 0 !important;
}

// ---------------------------------------


@import "/src/styles/variables";

// ---------------------------------------

.page
{
  background-color: white;
  padding: 10px 20px;
}

.form
{
  //width: 450px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;

  .inputs
  {

    label
    {
      display: block;
    }

    .input
    {
      width: 100%;
      max-width: 450px;
      margin-top: 3px;
    }
  }

  .alternativeIdName
  {
    width: 350px;
    height: 60px;
  }
}

.fieldWrapper
{
  margin-top: 10px;
}

// ---------------------------------------

.historyItem
{
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;

  .userData
  {
    margin-right: 16px;
  }

  .historyItemContainer
  {
    width: 100%;

    .historyItemHeader
    {
      display: flex;

      .timeFromNow
      {
        margin-left: 16px;
        color: @colorTextGray;
      }
    }

    .historyItemFields
    {
      margin-top: 8px;

      .historyItemFieldName
      {
        padding-right: 20px;
      }

      .historyItemFieldValues
      {
        width: 100%;
      }

      .before
      {
        max-width: 45%;
        background-color: #fbe9eb;
      }

      .arrow
      {
        width: auto;
        margin: 0 16px;
      }

      .after
      {
        max-width: 45%;
        background-color: #ecfdf0;
      }
    }
  }
}

.dataRows
{
  margin-top: 10px;
}

.eventId
{
  font-size: 12px;
  line-height: 14px;
}

// ---------------------------------------

.actionCreated,
.actionUpdated,
.actionDeleted
{
  padding: 3px 8px 4px;
  border-radius: 15px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.2);
}

.actionCreated
{
  background: #ff0;
}

.actionUpdated
{
  background: #cfc;
}

.actionDeleted
{
  background: #fcc;
}

// ---------------------------------------


@import '/src/styles/variables';

.positions
{
  display: flex;
  align-items: center;
  min-height: 18px;

  .positionsIcon
  {
    margin-right: 4px;

    color: @colorTextLightGray;
  }

  .positionCount
  {
    width: 20px;

    text-align: right;
  }

  .positionSeparator
  {
    width: 10px;

    text-align: center;
  }

  .positionTotalCount
  {
    width: 20px;

    text-align: left;
  }
}

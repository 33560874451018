@import '/src/styles/variables';

// ---------------------------------------

@keyframes expand
{
  from
  {
    max-height: 0;
    opacity: 0;
  }
  to
  {
    max-height: 100%;
    opacity: 1;
  }
}

.form
{
  animation-duration: 0.42s;
  animation-name: expand;

  display: flex;
  flex-direction: column;

  background: #fff;
  box-shadow: 0 0 10px @colorGray15;

  margin-bottom: 10px;

  // overflow: auto;

  .header
  {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 40px;
    padding: 0 0 0 20px;

    background: @candidateFormHeaderBg;

    .title
    {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
    }

    .btn
    {
      flex-grow: 0;
      flex-shrink: 0;

      min-width: 40px;
      height: 40px;

      & + .btn
      {
        margin-left: 10px;
      }
    }

    .spacer
    {
      flex-grow: 1;
      flex-shrink: 1;

      min-width: 0;
    }
  }

  .content
  {
    border-bottom: @borderGray15;

    .noteContentInput
    {
      resize: vertical;
      font-size: 16px;
      box-shadow: none;
      min-height: 100px;

      &::placeholder
      {
        color: rgba(92, 112, 128, 0.5) !important;
      }
    }
  }

  .actionBar
  {
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .btnSelect
    {
      min-height: 24px;
      background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
    }

    .saveButton
    {
      width: 130px;
    }
  }
}

// ---------------------------------------

@import '/src/styles/variables';

// ---------------------------------------

.collapseList
{
}

// ---------------------------------------

.itemWrapper
{
  margin: 10px 0;

  &.isCollapsed
  {
    margin: 0;
  }

  &.isCollapsed > .subItemsHeader,
  &.isCollapsed > .subItems
  {
    //display: none;
  }

  transition: margin .2s ease-out;
}

.item
{
  display: flex;
  flex-direction: row;

  min-height: 40px;

  user-select: none;
  border-radius: @collapseListItemsBorderRadius @collapseListItemsBorderRadius 0 0;

  border-bottom: @borderGray15;
  transition: border-bottom-color .2s ease-out;

  &.isCollapsed
  {
    border-radius: 0;
    //background: rgba(245, 248, 250, 0.5);
    background: linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #FFFFFF;

    &:not(.isPrevCollapsed)
    {
      border-top-left-radius: @collapseListItemsBorderRadius;
      border-top-right-radius: @collapseListItemsBorderRadius;
    }

    &:not(.isNextCollapsed)
    {
      border-bottom-left-radius: @collapseListItemsBorderRadius;
      border-bottom-right-radius: @collapseListItemsBorderRadius;
      transition: border-bottom-color .2s .1s ease-out;
      border-bottom-color: transparent;
    }
  }

  &:hover
  {
    &.isCollapsed
    {
      background: #ebf1f5;
    }

    .btnDownIcon
    {
      color: @colorLink !important;
    }
  }

  background: @colorHeaderBg;
}

// ---------------------------------------

.btnDown
{
  align-items: flex-start;
  align-self: stretch;

  min-width: 36px;
  padding-top: 12px;

  .btnDownIcon
  {
    color: @colorIcons !important;
  }
}

// ---------------------------------------

.itemContent
{
  display: flex;

  flex-grow: 1;
  flex-shrink: 1;

  min-width: 0;

  & > *
  {
    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;
  }
}

// ---------------------------------------

.subItemsHeader
{
  background: #ebf1f5;
}

.subItems
{
  border-radius: 0 0 @collapseListItemsBorderRadius @collapseListItemsBorderRadius;
  //  padding: 10px 10px 10px 36px;
  background: #ebf1f5;

  .subItem
  {
    min-height: 40px;

    //background: linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #fff;
    //border-bottom: @borderGray;

    &:first-child
    {
      border-top-left-radius: @collapseListItemsBorderRadius;
      border-top-right-radius: @collapseListItemsBorderRadius;
    }

    &:last-child
    {
      border-bottom: none;
      border-bottom-left-radius: @collapseListItemsBorderRadius;
      border-bottom-right-radius: @collapseListItemsBorderRadius;
    }

    &:hover
    {
      //background: @colorSubItemHoverBg;
    }
  }
}

// ---------------------------------------

.noItems
{
  overflow: hidden;
  border-radius: 0 0 @collapseListItemsBorderRadius @collapseListItemsBorderRadius;

  &.isCollapsed
  {
    display: none;
  }
}

// ---------------------------------------

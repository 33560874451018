@import '/src/styles/variables';

// ---------------------------------------

.dateTime
{
  display: flex;
  align-items: center;
  font-weight: 500;
}

.formIsCompletedPanel
{
  background: @feedbackFormIsCompletedPanelBackground;
  padding: 10px 15px;
  border-radius: 3px;
  height: 40px;
  display: flex;
  align-items: center;

  label
  {
    margin-bottom: unset;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.scrollY
{
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (min-width: @siteBreakPointMobileMax)
  {
    margin-right: -24px;
    padding-right: 24px;
  }
}

// ---------------------------------------

.contentWrapper
{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// ---------------------------------------

.scrollX
{
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: @siteBreakPointMobileMax)
  {
    flex-direction: row;
    overflow-x: auto;
  }
}

// ---------------------------------------

@import '/src/styles/variables';

.wrapper
{
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-height: 100%;
}

.main
{
  display: flex;
  overflow: hidden;
  flex-direction: row;

  flex-grow: 1;

  max-height: calc(100% - @appHeaderHeight);
  // background: @colorAppContentBg;

  background: @colorPageBackground;
}

.content
{
  position: relative;

  display: flex;
  flex-direction: column;

  flex: 1;

  min-width: 0;
  padding: 0 10px 10px;

  transition: background 0.2s ease-out;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    padding: 0 24px 24px;
  }

  &.scrollable
  {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.dialogsContainer
{
  position: absolute;
  z-index: @zindexDialog;
  top: 0;
  left: 0;
}

@import '/src/styles/variables';

.pill
{
  display: block;
  overflow: hidden;

  height: 20px;
  padding: 0 8px;

  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: @colorTextGray;
  border-radius: 50vh;
  background: @colorPillBg;

  font-size: 12px;
  line-height: 18px;

  &.outlined
  {
    border: 1px solid @colorBorderGray;
    background: none;
  }

  &.light
  {
    min-width: 0;
    height: 20px;
    padding: 0 10px;

    color: @colorTextGray;
    border: none;
    background: #FFFFFF;

    line-height: 20px;
  }

  &.mediumLight
  {
    min-width: 0;
    height: 20px;
    padding: 0 10px;

    color: @colorTextGray;
    border: none;
    background: @colorDatePillBg;

    line-height: 20px;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.position
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  min-height: 72px;
  padding: 6px 15px 7px;

  background: linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #fff;

  border-top: @borderGray15;

  &:first-child
  {
    min-height: 71px;
    border-top: none;
  }

  @media (min-width: @siteBreakPointDesktopMin)
  {
    flex-wrap: nowrap;
    min-height: 41px;
    padding: 0 25px;

    &:first-child
    {
      min-height: 40px;
    }
  }

  &:hover
  {
    cursor: pointer;
    background: #ebf1f5;

    .colPositionTemplate
    {
      color: @colorLink;
    }
  }

  &.unassigned
  {
    background: linear-gradient(0deg, rgba(255, 201, 64, 0.3), rgba(255, 201, 64, 0.3)), #fff;

    &:hover
    {
      background: linear-gradient(0deg, #f1dba3, #f1dba3), linear-gradient(0deg, rgba(245, 248, 250, 0.5), rgba(245, 248, 250, 0.5)), #fff;
    }
  }
}

// ---------------------------------------

.colPositionTemplate
{
  order: 1;
  width: 100%;
  max-width: calc(100% - 70px);
  padding-right: 10px;

  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    max-width: none;
    width: @positionsListWidth_PositionTemplate;
  }
}

.templateIcon
{
  margin-left: -1px;
  margin-right: 11px;
  color: #f5903d;

  &.isReady
  {
    margin-left: 0;
    margin-right: 10px;
    color: @colorLink;
  }
}

.colStaffRole
{
  order: 3;
  flex: 1;
  padding-right: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  line-height: 18px;
  color: @colorTextGray;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 2;
    max-width: @positionsListWidth_StaffRole;
  }
}

.colStaffedTotal
{
  display: flex;
  justify-content: flex-end;
  order: 2;
  flex: 1;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    order: 3;
    flex: 0;
    width: @positionsListWidth_StaffedTotal;
    padding-right: 10px;
  }
}

.colPositionGroup
{
  order: 4;
  margin-left: auto;

  @media (min-width: @siteBreakPointDesktopMin)
  {
  }
}

.confirmText
{
  padding-top: 1px;
  font-size: 12px;
  line-height: 24px;
  color: @colorTextGray;

  @media (min-width: @siteBreakPointDesktopMin)
  {
    font-size: 14px;
  }
}

// ---------------------------------------

@import '/src/styles/variables';

.header
{
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 0 8px 1px;

  .title
  {
    flex: 1;
    padding: 2px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &.isCollapsed
    {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn
  {
    flex-grow: 0;
    flex-shrink: 0;

    min-width: 40px;
    height: 40px;

    & + .btn
    {
      margin-left: 10px;
    }
  }

  .spacer
  {
    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;
  }
}

.participantsEditorText
{
  white-space: normal;
}

.actionBar
{
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btnSelect
  {
    min-height: 24px;
    background: linear-gradient(0deg, rgba(191, 204, 214, 0.15), rgba(191, 204, 214, 0.15)), #fff;
  }

  .saveButton
  {
    width: 130px;
  }
}

// ---------------------------------------

.datePillWrapper
{
  align-self: flex-start;
  display: flex;
  align-items: center;
  min-width: 180px;
  margin-left: 10px;
}

.titleDate
{

  white-space: nowrap;
  font-weight: normal;
  color: @colorTextLightGray;

  &.hide
  {
    display: none;
  }
}

.pill
{
  margin-left: auto;
  height: 22px;
  padding: 0 9px;

  color: @colorText;
  font-size: 14px;
  line-height: 22px;
  border-radius: 11px;

  &.completed
  {
    background: linear-gradient(0deg, rgba(117, 166, 104, 0.3), rgba(117, 166, 104, 0.3)), #fff;
  }

  &.upcoming
  {
    background: rgba(41, 101, 204, 0.2);
  }

  &.feedback
  {
    background: rgba(245, 144, 61, 0.3);
  }
}

// ---------------------------------------

.btnMore
{
  visibility: hidden;
  align-self: flex-start;
  margin: -4px 5px;

  &.isVisible
  {
    visibility: visible;
  }
}

// ---------------------------------------

.content
{
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .summary
  {
    margin-bottom: -5px;
    font-weight: 500;
    color: @colorText;
  }

  .timeRow
  {
    //font-weight: 500;
  }

  .timeRow, .participantsRow, .descriptionRow, .linkRow
  {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon
    {
      padding: 1px;
    }

    .participants, .description
    {
      width: 100%;
    }
  }

  .linkRow
  {
    padding-left: 10px;
    gap: 9px;
    font-size: 12px;
    color: @colorTextGray;
  }
}

.userItem
{
  display: flex;
  flex-direction: row;
  align-items: center;

  .userAvatar
  {
    margin-right: 10px;
  }

  .userDetails
  {
    .userEmail
    {
      font-size: 12px;
      line-height: 15px;
      color: @colorTextGray06;
    }
  }
}

// ---------------------------------------

.dateTimeEditor, .startTimeEditor, .endTimeEditor
{
  margin-right: 10px;
  color: @colorText;
}

.tag
{
  &:first-child, &:nth-child(2)
  {
    svg // hides "remove" icon
    {
      display: none;
    }
  }
}

.startTimePopover
{
  max-height: 215px;
  overflow: auto;
}

.timeInput
{
  width: 80px;
}

.duration
{
  font-weight: 400;
  color: @colorTextLightGray;
  padding-left: 10px;

  &.isNegative
  {
    color: @colorSlotOrange;
  }
}

.btnManageCall
{
  color: @colorLink !important;
  margin-left: 16px;
  margin-right: auto;
}

.participantsText
{
  margin: 6px 0;
  line-height: 20px;
}

.footer
{
  display: flex;
  align-items: center;

  min-height: 58px;
  //background-color: @colorBgFormSection;
  border-top: @borderGray15;
  margin: 0 5px;
  padding: 10px 4px;

  .checkCallCompleted
  {
    margin-bottom: 0;
    margin-left: auto;
  }
}

.noPermissions
{
  margin-left: auto;
  color: @colorTextLightGray;
}

// ---------------------------------------

.collapseListWrapper
{
  margin-bottom: 10px;
}

.collapseListItemWrapper
{
  margin: 0 !important;

  border: @borderGray15;
  border-radius: 3px;
  background: #f5f8fa;

  &.isEventDeleting
  {
    background: #fee;

    .collapseListItem
    {
      background: #fee !important;
    }
  }
}

.collapseListItem
{
  cursor: pointer;

  min-height: 38px;
  border-radius: 3px !important;
  background: #F5F8FA !important;
  border: 0;

  &:hover
  {
    color: @colorLinkHover;
  }
}

.collapseListItemContentWrapper
{
  margin: 0 14px;
  border-top: @borderGray15;
}

.collapseListItemContent
{
  margin: 0 -5px;
}

.collapseListBtn
{
  margin-left: 9px;
  padding-top: 11px;
  color: @colorTextGray !important;
  background: unset !important;
  opacity: .6;
}

// ---------------------------------------

.shrinkTop
{
  margin: -3px 0 0;
}

.shrink
{
  margin: -3px 0;
}

.compact
{
  margin: -6px 0;
}

.scheduledByLabel
{
  color: @candidateCommunicationActiveTabBackground;
}

.googleMeetBtn
{
  :global .bp4-icon
  {
    color: @colorLink !important;
  }

  margin-bottom: 3px;
}

// ---------------------------------------

.feedbackLinkIcon
{
  margin-right: 10px;
  color: @colorLink;

  &.orange
  {
    color: @colorSlotOrange;
  }
  &.gray
  {
    color: @colorTextLightGray;
  }
}

.feedbackLink
{
  color: @colorLink;

  &.gray
  {
    color: @colorTextGray;
  }
}

// ---------------------------------------

.historyView
{
  color: @colorTextGray;

  &:hover
  {
    color: @colorLink;
    text-decoration: none;

    .feedbackLinkIcon
    {
      color: @colorLink;
    }
  }
}

// ---------------------------------------

.confirmationIcon
{
  &.blue
  {
    color: @colorLink;
  }

  &.red
  {
    color: #db3737;
  }

  &.gray
  {
    color: @colorTextLightGray;
  }
}

@import '/src/styles/variables';

// ---------------------------------------

.wrapper
{
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.isCandidate
  {
    position: relative;

    &:after
    {
      content: '';
      position: absolute;
      top: -2px;
      right: -3px;
      width: 4px;
      height: 4px;
      background: @colorLink;
      border-radius: 2px;
    }
  }
}

// ---------------------------------------

.circle
{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  background: #e1e8ed;
  border-radius: 50%;
}

// ---------------------------------------

.icon
{
  opacity: 0.15;
}

.img
{
  max-width: 100%;
  //height: 100%;
  object-fit: cover;
  transform: translateY(0);
}

.text
{
  padding-right: 1px;
  font-size: 13px;
  line-height: 13px;
  color: @colorTextGray;
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.popover
{
  &.selectPopover
  {
    margin-top: 5px !important;
  }
}

// ---------------------------------------

.btn
{

  &.btnSelected,
  &.btnPlus
  {
    margin-right: 14px;

    background: rgba(138, 155, 168, 0.2);
    border-radius: 15px;
  }

  &.btnPlus
  {
    max-width: 30px;
  }

  .btnSelected
  {
  }
}

// ---------------------------------------

@import "/src/styles/variables";

// ---------------------------------------

.formContent
{
  flex: 1;
}

.formFooter
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

// ---------------------------------------

.info
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.photo
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0;

  overflow: hidden;
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  margin-right: 27px;

  background: #e1e8ed;
  box-shadow: 0 0 0 @colorGray15;
  border-radius: 3px;
}

.photoImg
{
  object-fit: cover;
  width: 100%;
}

.details
{
  display: flex;
  flex-direction: column;
}

.row
{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.left,
.right
{
  display: flex;
  flex-direction: column;
}

.fieldWrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin-bottom: 4px;
  min-height: 24px;
  margin-right: 24px;
}

.fieldIcon
{
  margin-right: 8px;
  color: @colorIcons;

  &.linkedin
  {
    color: #0077b7;
  }
}

.firstName
{
  margin-top: -7px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.role
{
  margin-bottom: 9px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

// ---------------------------------------

.selectItems
{
  margin-top: 30px;

  .title
  {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }

  .btnPlus
  {
    max-width: 30px;
    margin-right: 14px;

    background: rgba(138, 155, 168, 0.2);
    border-radius: 50%;
  }
}

// ---------------------------------------

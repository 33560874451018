// ---------------------------------------

@colorPageBackground: rgba(191, 204, 214, 0.15);
@colorPositionDialogItemBackground: linear-gradient(0deg, rgba(191, 204, 214, 0.2), rgba(191, 204, 214, 0.2)), #FFFFFF;
// ---------------------------------------

@colorAppContentBg: rgba(183, 206, 180, 0.3);
@colorPillBg: rgba(19, 124, 189, 0.2);
@colorItemBg: #E1E8ED;
@colorHeaderBg: #E1E8ED;
@colorHeaderHoverBg: darken(@colorItemBg, 1%);
@colorHeaderLightBg: rgba(167, 182, 194, 0.22);
@colorHeaderLightBg2: linear-gradient(0deg, @colorHeaderLightBg, @colorHeaderLightBg), #FFFFFF;
@colorSubItemBg: #FFFFFF;
@colorSubItemHoverBg: lighten(@colorSubItemBg, 2%);
@colorWarningBg: rgba(255, 201, 64, 0.3);
@colorBgOrderButton: #EBF1F5;
@colorBgOverlay: rgba(24, 32, 38, 0.3);
@colorBgFormSection: #F5F8FA;
@colorActionBg: #137CBD;
@colorActionSecondaryBg: rgba(19, 124, 189, 0.3);
@colorGroupBg: #E1E8ED;
@colorDragTargetBg: #BFCCD626;
@colorDropTargetBg: #8A9BA8B3;
@colorDatePillBg: #FFFFFF99;
@colorAddMoreInfoPill: #EAF0FA;
@colorBlue3: #2D72D2;

// ---------------------------------------

@colorIcons: #5C7080;
@colorTextDark: #000000;
@colorText: #182026;
@colorText08: rgba(24, 32, 38, 0.8);
@colorTextGray06: rgba(92, 112, 128, 0.6);
@colorTextGray08: rgba(92, 112, 128, 0.8);
@colorTextGray: #5C7080;
@colorTextLightGray: #8A9BA8;
@colorTextLightLightGray: #A0A0A0;
@colorMenuDivider: #DCDCDC;
@colorLink: #2965CC; // links, edit border
@colorLinkHover: #184A90;

// ---------------------------------------

@colorGray05: rgba(16, 22, 26, 0.05);
@colorGray10: rgba(16, 22, 26, 0.10);
@colorGray15: rgba(16, 22, 26, 0.15);
@colorGray20: rgba(16, 22, 26, 0.20);
@colorGray30: rgba(16, 22, 26, 0.30);

@colorBorder: #D3D6D8;
@colorBorderSelectInDialog: #CFD0D1;

@borderGray15: 1px solid @colorGray15;

.grayShadow()
{
  box-shadow: 0 0 0 @colorGray10, 0 1px 1px @colorGray20, 0 2px 6px @colorGray20;
}

// ---------------------------------------

@colorBgGray: rgba(92, 112, 128, 0.1);
@colorBorderGray: rgba(92, 112, 128, 0.5);

// ---------------------------------------

@disabledButtonColor: rgba(92, 112, 128, 0.6);
@disabledButtonBorderColor: rgba(0, 0, 0, 0.2);

// ---------------------------------------

@colorOrange: #F5903D; // rgba(245, 144, 61, 1)

@colorSlotBlue: @colorLink;
@colorSlotGreen: #0D8050;
@colorSlotOrange: @colorOrange;

// ---------------------------------------

@colorDropZoneBackground: rgba(206, 217, 224, 1); // #CED9E0;

@colorLightGreen: rgba(117, 166, 104, 0.3);
@colorCommunicationTitleBgBlue: rgba(41, 101, 204, 0.2);
@colorInputValidation: @colorSlotOrange;
@candidateFormHeaderBg: rgb(191, 209, 240); // rgba(41, 101, 204, 0.3);
@colorMoreInfoPillBgBlue: rgba(41, 101, 204, 0.1);
@candidateCommunicationActiveTabBackground: rgba(138, 155, 168, 1);
@feedbackFormIsCompletedPanelBackground: rgba(235, 241, 245, 1);

@import '/src/styles/variables';

// ---------------------------------------

.contentWrapper
{
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  gap: 10px;
}

.scroll
{
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: auto;

  gap: 10px;
}

// ---------------------------------------

.customerTeamCard
{
  display: flex;
  flex: 1;
  flex-direction: column;

  max-width: 100%;
  min-width: 100%;

  @media (min-width: @siteBreakPointMobileMax) // 768
  {
    min-width: calc((100% - 30px) / 4);
    max-width: calc((100% - 30px) / 4);
  }

  @media (min-width: @breakPointMacBookProDefault) // 1440
  {
    min-width: calc((100% - 50px) / 6);
    max-width: calc((100% - 50px) / 6);
  }

  &.fullWidth
  {
    min-width: 100%;
  }
}

// ---------------------------------------

.header
{
  display: flex;
  align-items: center;

  min-height: 50px;
  padding-left: 16px;

  // background: white;
  background: #e1e8ed;

  border-bottom: @borderGray15;
}

.headerContent
{
  display: flex;
  flex-wrap: wrap;
}

.teamName
{
  font-size: 14px;
  font-weight: 500;
}

// ---------------------------------------

.btnTeamMenu
{
  align-self: center;
  max-height: 30px;
  margin-left: auto;
}

.content
{
  flex: 1;
  display: flex;
  flex-direction: column;
}

// ---------------------------------------

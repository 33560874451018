@import "/src/styles/variables";

// ---------------------------------------

.searchInput
{
  .popoverWrapper();
}

// ---------------------------------------

.token
{
  color: #f00;
  font-weight: normal;
}

// ---------------------------------------

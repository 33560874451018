@import "/src/styles/variables";

.toastContent
{
  display: flex;
  flex-direction: row;

  .icon
  {
    margin-right: 8px;
  }
}

.appErrorToaster
{
  display: flex;
  color: @colorText;
  background-color: @colorBgOrderButton;
  min-width: 350px;

  @media (max-width: @siteBreakPointMobileMax)
  {
    width: 100%;
  }

  :global div.bp4-button-group
  {
    width: 35px;

    button
    {
      margin-left: -9px;
      margin-top: 9px;
    }
  }

  .iconContainer
  {
    position: relative;
    color: #fff;

    .icon
    {
      color: rgba(217, 130, 43, 1);
      margin: 8px;
    }

    .toastCountIcon
    {
      position: absolute;
      top: 4px;
      right: 0;
      padding: 0 6px;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;
      color: #fff;
      font-size: 10px;
      z-index: 1;

      &::before
      {
        position: absolute;
        z-index: 0;
        content: ' ';
        top: 50%;
        left: 0;
        width: 100%;
        height: 16px;
        background-color: @colorBgGray;
        background: red;
        color: #fff;
        border-radius: 8px;
        transform: translate(0, -50%);
      }

      .toastCount
      {
        position: relative;
      }
    }
  }

  :global .bp4-toast-message
  {
    padding: 5px;

    > div
    {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.errorCount
{
  position: relative;
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
  padding: 0 8px;

  &::before
  {
    position: absolute;
    content: ' ';
    top: 50%;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: @colorBgGray;
    border-radius: 12px;
    transform: translate(0, -50%);
  }
}

.errorMessage
{
  margin-left: 16px;
  margin-right: 32px;

  display: flex;
  align-items: center;

  font-weight: 500;
}

.errorMessageRow
{
  display: flex;
  width: calc(100% - 64px);
}

.errorDetailsButton
{
  margin: 9px 0;
  margin-left: auto;

  min-width: 80px;
  white-space: nowrap;
}

.stackTrace
{
  margin-top: 6px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-right: -26px;

  background-color: white;
  padding: 4px;
  color: #182026;

  font-size: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;

  min-height: 100px;
  max-height: 150px;

  box-shadow: 0 0 0 1px @colorGray20 inset;
  border-radius: 3px;
  overflow-y: auto;
}

.buttonsRow
{
  padding: 6px;
  padding-left: 8px;

  margin-bottom: 4px;
  margin-right: -32px;

  display: flex;
  justify-content: space-between;

  .copyButton
  {
    width: 125px;
  }

  .refreshButton
  {
    width: 125px;
  }
}

.collapse
{
  width: 100%;
}
